import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Container.css";

const SearchInput = ({ resultsPerPage, handleNewResultPreference }) => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const popover = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    var searchContent = window.location.pathname.split("/").pop();
    searchContent = decodeURIComponent(searchContent);

    if (searchContent) {
      setQuery(searchContent);
      setSearch(searchContent);
    }

    const setPopoverHeight = () => {
      const height = popover.current.clientHeight;
      popover.current.style.bottom = `-${height}px`;
    };

    setPopoverHeight();

    window.addEventListener("resize", setPopoverHeight);

    return () => {
      window.removeEventListener("resize", setPopoverHeight);
    };
  }, []);

  async function handleSearch() {
    if (!query) return;

    window.location.href = `/search/${query}`;
  }

  async function handlePopover() {
    setShowPopover(!showPopover);
  }

  return (
    <div className="container searchinput">
      <div className="wrapper">
        <div className="container">
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            type="text"
            placeholder="Search..."
          />
          <Tooltip sx={{ fontFamily: 'Red Hat Display, sans-serif' }} title={`${showPopover ? 'Close Search Filters' : 'Search Filters'}`} arrow placement="top">
            <i
              onClick={() => handlePopover()}
              className={`bx ${showPopover ? "bx-x" : "bx-filter-alt"} icon2`}
            />
          </Tooltip>
          <Tooltip sx={{ fontFamily: 'Red Hat Display, sans-serif' }} title="Search" arrow placement="top">
            <i onClick={() => handleSearch()} className="bx bx-search icon" />
          </Tooltip>
          <div ref={popover} className={`popover ${showPopover ? "open" : ""}`}>
            <div className="top">
              <div className="left">
                <h2>Filter By</h2>
                <div className="radios">
                  <label>
                    <input type="radio" name="kk" />
                    <div className="circle"></div>
                    <span>By Word</span>
                  </label>
                  <label>
                    <input type="radio" name="kk" />
                    <div className="circle"></div>
                    <span>By Key Word</span>
                  </label>
                  <label>
                    <input type="radio" name="kk" />
                    <div className="circle"></div>
                    <span>By Phrase</span>
                  </label>
                  <label>
                    <input type="radio" name="kk" />
                    <div className="circle"></div>
                    <span>By Page Name</span>
                  </label>
                </div>
              </div>
              <div className="right">
                <h2>Results per Page</h2>
                <div className="buttons">
                  <button
                    onClick={() => handleNewResultPreference(1)}
                    className={`${resultsPerPage === 1 ? "active" : ""}`}
                  >
                    1
                  </button>
                  <button
                    onClick={() => handleNewResultPreference(3)}
                    className={`${resultsPerPage === 3 ? "active" : ""}`}
                  >
                    3
                  </button>
                  <button
                    onClick={() => handleNewResultPreference(5)}
                    className={`${resultsPerPage === 5 ? "active" : ""}`}
                  >
                    5
                  </button>
                  <button
                    onClick={() => handleNewResultPreference(10)}
                    className={`${resultsPerPage === 10 ? "active" : ""}`}
                  >
                    10
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="results">
          <div className="left">
            <small>Showing results for "{search}"</small>
          </div>
          <div className="right">
            <small>{results.length} Results Found</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;

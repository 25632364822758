import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Announcement, CalCal, CalFeat, CalHero, Divide, Footer, HomeCTA, Navbar } from '../../../containers'
import { NavUp } from '../../../components'
import './Page.css'


const CalendarPage = () => {
  const [event, setEvent] = React.useState(null)

  const handleTheSit = (eventDayObj) => {
    setEvent(eventDayObj)
  }

  useEffect(() => {
    if (event) {
      const calCal = document.querySelector('.container.calcal')
      calCal.scrollIntoView({ behavior: 'smooth' })
    }
  }, [event])


  return (
    <main className='view calendar'>
      <Helmet>
        <title>CCPR - Calendar</title>
        {/* Metas */}
      </Helmet>
      <section className="container">
        <Navbar transparent={true} color="#000" location='calendar' />
        <NavUp />
        <div className="content">
          <CalHero />
          <CalCal externalEvent={event} />
          <CalFeat onClick={handleTheSit} />
          <Divide />
          <HomeCTA />
          <Announcement border={true} version='2' />
          <Footer />
        </div>
      </section>
    </main>
  )
}

export default CalendarPage

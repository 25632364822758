import {
  About,
  Contact,
  Donate,
  Home,
  Login,
  RecoveryCenter,
  Search,
  CalendarPage,
  HelpLine,
  Dashboard,
} from "./views";
import { Cookie } from "./components";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  LightboxProvider,
  EasyModalProvider,
  NotificationProvider,
  AlertProvider,
  AuthProvider,
} from "./providers";
import { RequireAuth } from "./utils";
import "./App.css";

function App() {
  return (
    <section className="App">
      <Helmet>
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <Cookie />
      <LightboxProvider>
        <NotificationProvider>
          <AlertProvider>
            <EasyModalProvider>
              <AuthProvider>
                <BrowserRouter>
                  <Routes>
                    <Route index element={<Home />} />

                    <Route path="/home" element={<Home />} />

                    <Route path="/about" element={<About />} />

                    <Route path="/help-line" element={<HelpLine />} />

                    <Route path="/calendar" element={<CalendarPage />} />

                    <Route
                      path="/recovery-center"
                      element={<RecoveryCenter />}
                    />

                    <Route path="/donate" element={<Donate />} />

                    <Route path="/contact" element={<Contact />} />

                    <Route path="/search/:id" element={<Search />} />

                    <Route path="/login" element={<Login />} />
    
                    {/* Secure Routes */}
                    <Route path="/dashboard" element={ <RequireAuth><Dashboard /></RequireAuth>} />
                  </Routes>
                </BrowserRouter>
              </AuthProvider>
            </EasyModalProvider>
          </AlertProvider>
        </NotificationProvider>
      </LightboxProvider>
    </section>
  );
}

export default App;

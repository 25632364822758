import React, { useEffect, useState } from "react";
import "./Component.css";

const ForgotPass = ({ onClickForgotPass, providedUsername }) => {
  const [close, setClose] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [result, setResult] = useState({
    shown: false,
    success: null,
    message: "",
  });

  useEffect(() => {
    if (providedUsername) {
      setUser(providedUsername);
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, []);

  async function handleClose() {
    if (loading) return;
    setClose(true);
    setTimeout(() => {
      onClickForgotPass();
    }, 500);
  }

  async function onChangeUser(e) {
    setUser(e.target.value);
    if (e.target.value.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  async function handleForgotPass() {
    setLoading(true);
    setDisabled(true);
    setTimeout(() => {
      setLoading(false);
      setUser("");
      setResult({
        shown: true,
        success: true,
        message: "Your password reset request has been submitted.",
      });
    }, 5000);
  }

  return (
    <div className={`component forgot ${close ? "closed" : ""}`}>
      <div className="wrapper">
        <i onClick={() => handleClose()} className="bx bx-x icon" />
        <div className={`content-wrapper ${result.shown ? "hidden" : ""}`}>
          <h1>Forgot Password?</h1>
          <div className="input-container">
            <label htmlFor="">Username or Email</label>
            <div className="container">
              <input
                type="text"
                onChange={(e) => onChangeUser(e)}
                value={user}
              />
            </div>
          </div>
          <button
            disabled={disabled}
            className={loading ? "loading" : ""}
            onClick={() => handleForgotPass()}
          >
            <p className="text">Submit</p>
          </button>
        </div>
        <div className="content-wrapper">
            Content Post Submission!
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;

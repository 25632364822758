import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../../contexts";
import { LoginContainer } from "../../../containers";
import { ForgotPass } from "../../../components";
import "./Page.css";


const Login = () => {
  const [forgotPassVis, setForgotPassVis] = React.useState(false);
  const [providedUsername, setProvidedUsername] = React.useState("");
  const { createNotification } = React.useContext(NotificationContext);
  const navigate = useNavigate();
  

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const message = params.get("message");

    if(!message) return;

    if(message === "SessionExpired") {
      createNotification('#C72231', 'Session Expired', 'Please login again', 'bx-timer bx-tada', 3000);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    
  }, [])

  const onClickForgotPass = () => {
    setForgotPassVis(!forgotPassVis);
  };

  return (
    <main className="view login">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <section className="container">
        {forgotPassVis ? <ForgotPass providedUsername={providedUsername} onClickForgotPass={onClickForgotPass} /> : null}
        <button onClick={() => navigate(-1)}>
          <i className="bx bx-left-arrow-alt" />
          <p>Back</p>
        </button>
        <LoginContainer onClickForgotPass={onClickForgotPass} providedUsernameFunc={setProvidedUsername} />
      </section>
    </main>
  );
};

export default Login;

import React, { Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import {
  Footer,
  Navbar,
  RCContact,
  RCCTA,
  RCGallery,
  RCHero,
  RCTour,
} from "../../../containers";
import "./Page.css";
import { NavUp } from "../../../components";

const Announcement = lazy(() =>
  import("../../../containers/Common/Announcement/Container")
);

const RecoveryCenter = () => {
  return (
    <main className="view recovercent">
      <Helmet>
        <title>Recovery Center</title>
      </Helmet>
      <section className="container">
        <Navbar location="recovercent" />
        <NavUp />
        <div className="content">
          <RCHero />
          <RCTour />
          <RCGallery />
          <Suspense>
            <Announcement version="3" />
          </Suspense>
          <RCContact />
          <RCCTA />
          <Footer />
        </div>
      </section>
    </main>
  );
};

export default RecoveryCenter;

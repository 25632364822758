import React from "react";
import { RCBG } from "../../../assets";
import { CircularProgress } from "@mui/material";
import { LightboxContext } from "../../../contexts";
import "./Container.css";

const CalFeat = ({ onClick }) => {
  const [loading, setLoading] = React.useState(true);
  const [events, setEvents] = React.useState([]);
  const { createLightboxImage } = React.useContext(LightboxContext);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);

      const eventsArr = [
        {
          id: 5,
          title: "Event 1 Title",
          status: "completed",
          icon: "bx-check",
          date: "12/12/2021",
          time: "10:00AM - 12:00PM",
          location: {
            name: "1 Taconic Place, Chatham, NY 12037",
            link: "https://www.google.com/maps",
          },
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
          links: [
            {
              name: "Link 1",
              link: "https://www.google.com",
            },
            {
              name: "Link 2",
              link: "https://www.google.com",
            },
            {
              name: "Link 3",
              link: "https://www.google.com",
            },
          ],
          images: [
            {
              name: "Image 1",
              description: "Image 1 description",
              link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
            },
            {
              name: "Image 2",
              description: "Image 2 description",
              link: "https://www.google.com",
            },
            {
              name: "Image 3",
              description: "Image 3 description",
              link: "https://www.google.com",
            },
          ],
          thumbnail: {
            src: RCBG,
            alt: "Event 1",
          },
        },
        {
          id: 2,
          title: "Event 1 Title",
          status: "completed",
          icon: "bx-check",
          date: "12/12/2021",
          time: "10:00AM - 12:00PM",
          location: {
            name: "1 Taconic Place, Chatham, NY 12037",
            link: "https://www.google.com/maps",
          },
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
          links: [
            {
              name: "Link 1",
              link: "https://www.google.com",
            },
            {
              name: "Link 2",
              link: "https://www.google.com",
            },
            {
              name: "Link 3",
              link: "https://www.google.com",
            },
          ],
          images: [
            {
              name: "Image 1",
              description: "Image 1 description",
              link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
            },
            {
              name: "Image 2",
              description: "Image 2 description",
              link: "https://www.google.com",
            },
            {
              name: "Image 3",
              description: "Image 3 description",
              link: "https://www.google.com",
            },
          ],
          thumbnail: {
            src: RCBG,
            alt: "Event 1",
          },
        },
        {
          id: 4,
          title: "Event 1 Title",
          status: "completed",
          icon: "bx-check",
          date: "12/12/2021",
          time: "10:00AM - 12:00PM",
          location: {
            name: "Recovery Center",
            link: "https://www.google.com/maps",
          },
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
          links: [
            {
              name: "Link 1",
              link: "https://www.google.com",
            },
            {
              name: "Link 2",
              link: "https://www.google.com",
            },
            {
              name: "Link 3",
              link: "https://www.google.com",
            },
          ],
          images: [
            {
              name: "Image 1",
              description: "Image 1 description",
              link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
            },
            {
              name: "Image 2",
              description: "Image 2 description",
              link: "https://www.google.com",
            },
            {
              name: "Image 3",
              description: "Image 3 description",
              link: "https://www.google.com",
            },
          ],
          thumbnail: {
            src: RCBG,
            alt: "Event 1",
          },
        },
      ];

      setEvents(eventsArr);
    }, 3000);
  }, []);

  return (
    <div className="container calfeat">
      <h2>
        Featured <span>Events</span>
      </h2>
      <div className="events">
        {!loading && events.length !== 0 ? (
          events.map((event, index) => (
            <div key={index} id={event.id} className="event">
              <img
                src={event.thumbnail.src}
                alt={event.thumbnail.alt}
                onClick={() =>
                  createLightboxImage(
                    event.thumbnail.src,
                    event.title,
                    event.thumbnail.alt
                  )
                }
              />
              <div className="under">
                <div className="info-container">
                  <h6>
                    <i className="bx bx-map" />
                    Location:
                  </h6>
                  <a href={event.location.link}>{event.location.name}</a>
                </div>
                <div className="info-container">
                  <h6>
                    <i className="bx bx-timer" />
                    Time:
                  </h6>
                  <p>{event.time}</p>
                </div>
                <div className="info-container">
                  <h6>
                    <i className="bx bx-calendar" />
                    Date:
                  </h6>
                  <p>{event.date}</p>
                </div>
              </div>
              <div className="main">
                <h4>{event.title}</h4>
                <button onClick={() => onClick(new Date(event.date))}>
                  View Event
                </button>
              </div>
            </div>
          ))
        ) : loading ? (
          <div style={{ color: "#77509F" }}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <div>No events found</div>
        )}
      </div>
    </div>
  );
};

export default CalFeat;

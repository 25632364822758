import { useContext } from "react";
import { AlertContext } from "../../../contexts";
import "./Container.css";

const RCContact = () => {
  const { createAlert, copyToClipboard } = useContext(AlertContext);

  const createLink = (href, target = "_self") => {
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = href;
    anchor.target = target;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleClick = (id, content, contentType) => {
    const commonButtons = {
      1: {
        text: "Copy",
        onClick: () => copyToClipboard(content, contentType),
        close: true,
      },
    };

    let alertDetails = {};

    if (id === 1) {
      alertDetails = {
        title: "Email",
        message: `Email us at <span>${content}</span> or click the buttons below to copy the email address or email us directly.`,
        buttons: {
          ...commonButtons,
          2: {
            text: "Email",
            onClick: () => createLink(`mailto:${content}`),
            close: true,
          },
        },
      };
    } else if (id === 2) {
      alertDetails = {
        title: "Phone",
        message: `Call us at <span>${content}</span> or click the buttons below to copy the phone number or call us directly.`,
        buttons: {
          ...commonButtons,
          2: {
            text: "Call",
            onClick: () => createLink(`tel:${content}`),
            close: true,
          },
        },
      };
    } else if (id === 3) {
      alertDetails = {
        title: "Address",
        message: `Visit us at <span>${content}</span> or click the buttons below to copy the address or view the location on Google Maps.`,
        buttons: {
          ...commonButtons,
          2: {
            text: "Map",
            onClick: () =>
              createLink(
                "https://www.google.com/maps?sca_esv=3b6dba99caad62d0&output=search&q=1+Taconic+Pl,+Chatham,+NY+12037&source=lnms&fbs=AEQNm0Aa4sjWe7Rqy32pFwRj0UkW1DRbm01j6DCVS0r1sTxn7rqU1uhV7mfeoM5P4clGxahOScsLVsm0WLMq5F1C9PC1G3l2jIBonkgeURAESkF3LiyyVTakg84IEth-mGezh9C41GqR8jZ-TenNtfmTzSnk7xpiKLAVGmITlb-zIxGC33FPH_GaS4gsQCcioi6gE9VcGrZmE-ZVppLJbMSTwnYB_aocNA&entry=mc&ved=1t:200715&ictx=111",
                "_blank"
              ),
            close: true,
          },
        },
      };
    } else if (id === 4) {
      alertDetails = {
        title: "Mailing Address",
        message: `Mail us at <span>${content}</span> or click the button below to copy the mailing address.`,
        buttons: commonButtons,
      };
    }

    createAlert(alertDetails);
  };

  return (
    <div className="container rccontact">
      <div className="top">
        <h1>Get in Touch</h1>
        <p>
          Below is contact information for the Recovery Center. If you aren't
          able to find what you are looking for{" "}
          <a href="/contact">Click Here</a> to get a full list of contact
          information.
        </p>
      </div>
      <div className="main">
        <div className="left">
          <ul>
            <li>
              <i className="bx bx-mail-send icon" />
              <h2>Email</h2>
              <p onClick={(e) => handleClick(1, e.target.textContent, "Email")}>
                email@email.com
              </p>
            </li>
            <li>
              <i className="bx bx-phone icon" />
              <h2>Phone</h2>
              <p
                onClick={(e) =>
                  handleClick(2, e.target.textContent, "Phone Number")
                }
              >
                +0 000 000 0000
              </p>
            </li>
            <li>
              <i className="bx bx-map icon" />
              <h2>Address</h2>
              <p
                onClick={(e) => handleClick(3, e.target.textContent, "Address")}
              >
                1 Taconic Pl, Chatham, NY 12037
              </p>
            </li>
            <li>
              <i className="bx bx-envelope icon" />
              <h2>Mailing Address</h2>
              <p
                onClick={(e) =>
                  handleClick(4, e.target.textContent, "Mailing Address")
                }
              >
                P.O. Box 000, Chatham, NY 12037
              </p>
            </li>
          </ul>
        </div>
        <div className="right">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1165.3139536069796!2d-73.60628170620754!3d42.346765019931006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ddf24d4b3b9937%3A0x52759d9ccbdd10ac!2s1%20Taconic%20Pl%2C%20Chatham%2C%20NY%2012037!5e1!3m2!1sen!2sus!4v1706213847512!5m2!1sen!2sus"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default RCContact;

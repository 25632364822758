import React from 'react'
import { Helmet } from 'react-helmet'
import { Announcement, DonoBlurb, DonoCTA, DonoHero, Footer, Navbar } from '../../../containers'
import './Page.css'
import { NavUp } from '../../../components'

const Donate = () => {
  return (
    <main className='view donate'>
      <Helmet>
        <title>Donate</title>
      </Helmet>
      <section className="container">
        <Navbar location='donate' />
        <NavUp />
        <div className="content">
          <DonoHero />
          <DonoBlurb />
          <div className="infinite">
            Infinit Scroll of Sponors
          </div>
          <div className="donate">
            Donate
          </div>
          <div className="donators">
            Donators!
          </div>
          <Announcement />
          <DonoCTA />
          <Footer />
        </div>
      </section>
    </main>
  )
}

export default Donate

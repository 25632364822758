import React from "react";
import "./Container.css";

const ContHero = () => {
  return (
    <div className="container conthero">
      <div className="left">
        <figure>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 500 400"
            style={{ width: "188px" }}
            xmlSpace="preserve"
            data-imageid="sending-emails-84"
            className="illustrations_image"
          >
            <style type="text/css">
              {`
                .st0_sending-emails-84{fill:#FFFFFF;}
                .st1_sending-emails-84{fill:#093F68;}
                .st2_sending-emails-84{fill:#68E1FD;}
                .st3_sending-emails-84{fill:#F56132;}
                .st4_sending-emails-84{fill:#FFBC0E;}
                .st5_sending-emails-84{fill:#70CC40;}
              `}
            </style>
            <g id="screen_sending-emails-84">
              <path
                className="st0_sending-emails-84"
                d="M435.4,153.6V366c0,5.5-4.5,10-10,10H57.7c-5.5,0-10-4.5-10-10V153.6H435.4z"
              />
              <path
                className="st1_sending-emails-84"
                d="M425.4,377.9H57.7c-6.6,0-11.9-5.3-11.8-11.9l0,0V153.6c0-1,0.8-1.9,1.8-1.9c0,0,0,0,0,0h387.6c1,0,1.9,0.8,1.9,1.9V366C437.2,372.6,431.9,377.9,425.4,377.9C425.4,377.9,425.4,377.9,425.4,377.9z M49.6,155.5V366c0,4.5,3.6,8.1,8.1,8.1h367.6c4.5,0,8.1-3.6,8.1-8.1V155.5H49.6z"
              />
              <path
                className="st2_sending-emails-84 targetColor"
                d="M53.1,125.3H430c3,0,5.4,2.4,5.4,5.4v0v23l0,0H47.7l0,0v-23C47.7,127.7,50.2,125.3,53.1,125.3z"
                style={{ fill: "#77509F" }}
              />
              <path
                className="st1_sending-emails-84"
                d="M435.4,155.5H47.7c-1,0-1.8-0.8-1.8-1.9l0,0v-18.4c0-6.5,5.3-11.8,11.9-11.8h367.6c6.5,0,11.8,5.3,11.9,11.8v18.4C437.2,154.7,436.4,155.5,435.4,155.5L435.4,155.5z M49.6,151.8h383.9v-16.5c0-4.5-3.6-8.1-8.1-8.1H57.7c-4.5,0-8.1,3.6-8.1,8.1L49.6,151.8z"
              />
              <circle className="st3_sending-emails-84" cx="69" cy="138.1" r="4.4" />
              <path
                className="st1_sending-emails-84"
                d="M69,133.7c2.4,0,4.4,2,4.4,4.4s-2,4.4-4.4,4.4s-4.4-2-4.4-4.4S66.6,133.7,69,133.7L69,133.7 M69,130c-4.5,0-8.1,3.6-8.1,8.1c0,4.5,3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1c0,0,0,0,0,0C77.1,133.6,73.5,130,69,130z"
              />
              <circle className="st4_sending-emails-84" cx="89" cy="138.1" r="4.4" />
              <path
                className="st1_sending-emails-84"
                d="M89,133.7c2.4,0,4.4,1.9,4.4,4.3s-1.9,4.4-4.3,4.4c-2.4,0-4.4-1.9-4.4-4.3c0,0,0,0,0,0C84.6,135.7,86.6,133.7,89,133.7 M89,130c-4.5,0-8.1,3.6-8.1,8.1c0,4.5,3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1c0,0,0,0,0,0C97.1,133.6,93.5,130,89,130z"
              />
              <circle className="st5_sending-emails-84" cx="109" cy="138.1" r="4.4" />
              <path
                className="st1_sending-emails-84"
                d="M109,133.7c2.4,0,4.4,2,4.4,4.4s-2,4.4-4.4,4.4s-4.4-2-4.4-4.4S106.6,133.7,109,133.7L109,133.7 M109,130c-4.5,0-8.1,3.6-8.1,8.1c0,4.5,3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1c0,0,0,0,0,0C117.1,133.6,113.5,130,109,130z"
              />
              <path
                className="st4_sending-emails-84"
                d="M120.6,312.7h241.8c9.3,0,16.8,7.5,16.8,16.8v4c0,9.3-7.5,16.8-16.8,16.8H120.6c-9.3,0-16.8-7.5-16.8-16.8v-4C103.8,320.2,111.3,312.7,120.6,312.7z"
              />
              <path
                className="st1_sending-emails-84"
                d="M360.5,352.2H122.6c-11.4,0-20.7-9.2-20.7-20.7s9.2-20.7,20.7-20.7h237.9c11.4,0,20.7,9.2,20.7,20.7S371.9,352.2,360.5,352.2z M122.6,314.6c-9.4,0-17,7.6-17,16.9c0,9.4,7.6,17,16.9,17c0,0,0,0,0,0h237.9c9.4-0.7,16.4-8.9,15.6-18.3c-0.6-8.4-7.3-15-15.6-15.6H122.6z"
              />
              <path
                className="st1_sending-emails-84"
                d="M220.6,327c-0.3-0.4-0.7-0.8-1.2-0.9c-0.5-0.2-1-0.3-1.6-0.3c-0.3,0-0.6,0-0.9,0.1c-0.3,0.1-0.6,0.2-0.8,0.4c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.3-0.2,0.6-0.2,0.9c0,0.5,0.2,0.9,0.6,1.2c0.4,0.3,0.9,0.6,1.4,0.7c0.6,0.2,1.1,0.4,1.8,0.6c0.6,0.2,1.2,0.5,1.8,0.8c0.6,0.3,1,0.8,1.4,1.4c0.4,0.7,0.6,1.5,0.6,2.2c0,0.8-0.1,1.5-0.5,2.2c-0.3,0.6-0.7,1.1-1.3,1.6c-0.6,0.4-1.2,0.7-1.8,0.9c-0.7,0.2-1.5,0.3-2.2,0.3c-1,0-1.9-0.2-2.8-0.5c-0.9-0.3-1.7-0.9-2.3-1.6l2.1-2c0.3,0.5,0.8,0.9,1.4,1.2c0.8,0.4,1.8,0.5,2.8,0.3c0.3-0.1,0.6-0.2,0.9-0.4c0.3-0.2,0.5-0.4,0.6-0.7c0.2-0.3,0.3-0.6,0.2-0.9c0-0.5-0.2-1-0.6-1.3c-0.4-0.3-0.9-0.6-1.4-0.8c-0.6-0.2-1.1-0.4-1.8-0.6c-0.6-0.2-1.2-0.5-1.8-0.8c-0.6-0.3-1-0.8-1.4-1.3c-0.4-0.7-0.6-1.5-0.6-2.2c0-0.7,0.2-1.5,0.5-2.1c0.3-0.6,0.8-1.1,1.3-1.5c0.6-0.4,1.2-0.7,1.9-0.9c0.7-0.2,1.4-0.3,2.1-0.3c0.8,0,1.6,0.1,2.4,0.4c0.8,0.3,1.5,0.7,2.1,1.2L220.6,327z"
              />
              <path
                className="st1_sending-emails-84"
                d="M225.7,323.6h10.4v2.5h-7.6v3.9h7.2v2.5h-7.2v4.3h8v2.5h-10.8V323.6z"
              />
              <path
                className="st1_sending-emails-84"
                d="M239.3,323.6h3.7l7.6,11.7l0,0v-11.7h2.8v15.8h-3.6l-7.8-12.1h-0.1v12.1h-2.8L239.3,323.6z"
              />
              <path
                className="st1_sending-emails-84"
                d="M257,323.6h6.2c1,0,2,0.2,3,0.5c0.9,0.3,1.8,0.8,2.6,1.5c0.8,0.7,1.4,1.5,1.8,2.5c0.5,1.1,0.7,2.3,0.7,3.5c0,1.2-0.2,2.4-0.8,3.5c-0.5,0.9-1.2,1.8-2,2.5c-0.8,0.6-1.7,1.1-2.7,1.4c-0.9,0.3-1.9,0.5-2.9,0.5H257V323.6z M261.9,336.9c0.8,0,1.7-0.1,2.5-0.3c0.7-0.2,1.4-0.5,2-0.9c0.6-0.4,1-1,1.4-1.6c0.4-0.8,0.5-1.6,0.5-2.5c0-0.8-0.1-1.7-0.5-2.5c-0.3-0.6-0.7-1.2-1.2-1.7c-0.6-0.4-1.2-0.7-1.9-0.9c-0.8-0.2-1.6-0.3-2.4-0.3h-2.6v10.7L261.9,336.9z"
              />
            </g>
            <g id="email_sending-emails-84">
              <rect
                x="244.7"
                y="172.7"
                className="st2_sending-emails-84 targetColor"
                width="208.9"
                height="123.6"
                style={{ fill: "#77509F" }}
              />
              <path
                className="st1_sending-emails-84"
                d="M453.6,298.2H244.7c-1,0-1.9-0.8-1.9-1.9l0,0V172.7c0-1,0.8-1.9,1.8-1.9h0h208.9c1,0,1.9,0.8,1.9,1.8c0,0,0,0,0,0v123.6C455.4,297.4,454.6,298.2,453.6,298.2C453.6,298.2,453.6,298.2,453.6,298.2z M246.6,294.5h205.2V174.6H246.6L246.6,294.5z"
              />
              <polygon
                className="st2_sending-emails-84 targetColor"
                points="349.1,221.3 244.7,296.4 453.6,296.4"
                style={{ fill: "#77509F" }}
              />
              <path
                className="st1_sending-emails-84"
                d="M453.6,298.2H244.7c-1,0-1.9-0.8-1.9-1.8c0-0.6,0.3-1.2,0.8-1.5l104.4-75c0.6-0.5,1.5-0.5,2.2,0l104.4,75c0.8,0.6,1,1.7,0.4,2.6C454.8,297.9,454.2,298.2,453.6,298.2L453.6,298.2z M250.5,294.5h197.4l-98.7-70.9L250.5,294.5z"
              />
              <polygon
                className="st2_sending-emails-84 targetColor"
                points="349.1,247.8 244.7,172.7 453.6,172.7"
                style={{ fill: "#77509F" }}
              />
              <path
                className="st1_sending-emails-84"
                d="M349.1,249.6c-0.4,0-0.8-0.1-1.1-0.4l-104.4-75c-0.8-0.6-1-1.8-0.4-2.6c0.3-0.5,0.9-0.8,1.5-0.8h208.9c1,0,1.9,0.8,1.9,1.8c0,0.6-0.3,1.2-0.8,1.5l-104.4,75C349.9,249.5,349.5,249.6,349.1,249.6z M250.5,174.6l98.7,70.9l98.7-70.9H250.5z"
              />
              <path
                className="st1_sending-emails-84"
                d="M225.9,204.4h-84.3c-1-0.1-1.8-1-1.7-2c0.1-0.9,0.8-1.7,1.7-1.7h84.3c1,0.1,1.8,1,1.7,2C227.5,203.6,226.8,204.3,225.9,204.4z"
              />
              <path
                className="st1_sending-emails-84"
                d="M208.3,232.3H89c-1-0.1-1.8-1-1.7-2c0.1-0.9,0.8-1.7,1.7-1.7h119.3c1-0.1,1.9,0.7,2,1.7c0.1,1-0.7,1.9-1.7,2C208.5,232.3,208.4,232.3,208.3,232.3z"
              />
              <path
                className="st1_sending-emails-84"
                d="M260.6,260.7H129.7c-1-0.1-1.8-1-1.7-2c0.1-0.9,0.8-1.7,1.7-1.7h130.9c1-0.1,1.9,0.7,2,1.7c0.1,1-0.7,1.9-1.7,2C260.8,260.7,260.7,260.7,260.6,260.7z"
              />
            </g>
            <g id="cursor_sending-emails-84">
              <polygon
                className="st0_sending-emails-84"
                points="363.8,333.5 326.8,324.1 336.2,361.1 344.6,352.8 368.4,376.6 379.3,365.7 355.5,341.9"
              />
              <path
                className="st1_sending-emails-84"
                d="M368.4,378.4c-0.5,0-1-0.2-1.3-0.5l-22.5-22.5l-7,7c-0.7,0.7-1.9,0.7-2.6,0c-0.2-0.2-0.4-0.5-0.5-0.9l-9.4-37.1c-0.3-1,0.3-2,1.3-2.3c0.3-0.1,0.6-0.1,0.9,0l37.1,9.5c1,0.2,1.6,1.3,1.3,2.2c-0.1,0.3-0.3,0.6-0.5,0.9l-7.1,7l22.5,22.5c0.7,0.7,0.7,1.9,0,2.6l-10.9,10.9C369.4,378.2,368.9,378.4,368.4,378.4z M344.6,350.9c0.5,0,1,0.2,1.3,0.5l22.5,22.5l8.2-8.2l-22.5-22.5c-0.7-0.7-0.7-1.9,0-2.6l6.1-6.1l-30.9-7.9l7.9,30.9l6.1-6.1C343.6,351.1,344.1,350.9,344.6,350.9z"
              />
            </g>
          </svg>
        </figure>
      </div>
      <div className="right">
        <h1>Leave us a Message</h1>
        <p>
          Have a question or concern? Send us a message and we'll get back to
          you as soon as possible.
        </p>
      </div>
    </div>
  );
};

export default ContHero;

import React from 'react'
import { Helmet } from 'react-helmet'
import { Navbar } from '../../../containers'
import './Page.css'

const About = () => {
  return (
    <main className='view about'>
      <Helmet>
        <title>About CCPR</title>
      </Helmet>
      <section className="container">
        <Navbar location='about' />
        <div className="content">
            About Page!
        </div>
      </section>
    </main>
  )
}

export default About

import React from "react";
import { Button } from "../../../components";
import "./Container.css";

const RCCTA = () => {
  return (
    <div className="container rccta">
      <h1>Join Us in Making a Difference</h1>
      <p>
        Discover how you can support our mission and help those in recovery.
      </p>
      <div className="buttons">
        <Button
          text="Donate"
          hover={true}
          background="#77509F"
          color="#fff"
          onClick={() => {
            window.location.href = "/donate";
          }}
        />
        <Button
          text="Learn More"
          border={"1px solid #77509F"}
          inverse={true}
          background="transparent"
          color="#fff"
          onClick={() => {
            window.location.href = "/about";
          }}
        />
      </div>
    </div>
  );
};

export default RCCTA;

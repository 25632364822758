import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Navbar,
  HomeHeroHeader,
  Announcement,
  HomeCTA,
  HomeAbout,
  HomeFeatProg,
  HomeGal,
  Footer,
} from "../../../containers";
import { NavUp } from "../../../components";
import "./Page.css";

const Home = () => {
  const [navbarTransparency, setNavbarTransparency] = useState(true);
  const [navbarColor, setNavbarColor] = useState("#fff");

  useEffect(() => {
    const handleScroll = () => {
      const scrolledHeight = window.scrollY;
      const viewportHeight = window.innerHeight;
      const triggerHeight = 0.4 * viewportHeight - 4 * 16;

      if (scrolledHeight >= triggerHeight) {
        setNavbarColor("#000");
        setNavbarTransparency(false);
      } else {
        setNavbarColor("#fff");
        setNavbarTransparency(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className="view home">
      <Helmet>
        <title>CCPR - Home</title>

        {/* Metas */}
      </Helmet>
      <section className="container">
        <Navbar
          transparent={navbarTransparency}
          color={navbarColor}
          location="home"
        />
        <NavUp />
        <div className="content">
          <HomeHeroHeader />
          <HomeCTA version={'first'} />
          <HomeAbout />
          <Announcement version={'1'} />
          <HomeFeatProg />
          <HomeGal />
          <HomeCTA />
          <Announcement border={true} version={'2'} />
          <Footer />
        </div>
      </section>
    </main>
  );
};

export default Home;

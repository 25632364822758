import React from "react";
import { Helmet } from "react-helmet";
import {
  Announcement,
  Footer,
  Navbar,
  SearchInput,
  SearchCTA,
  SearchResults,
} from "../../../containers";
import { NavUp } from "../../../components";
import "./Page.css";

const Search = () => {
  const [searchContent, setSearchContent] = React.useState(
    decodeURIComponent(window.location.pathname.split("/").pop())
  );
  const [resultsPerPage, setResultsPerPage] = React.useState(
    parseInt(localStorage.getItem("resultsPerPage")) || 3
  );

  async function handleNewResultPreference(pageNum) {
    if (typeof pageNum !== "number") {
      pageNum = parseInt(pageNum);
    }

    localStorage.setItem("resultsPerPage", pageNum);
    setResultsPerPage(pageNum);
  }

  return (
    <main className="view search">
      <Helmet>
        <title>Search - {searchContent}</title>
      </Helmet>
      <section className="container">
        <Navbar location="search" />
        <NavUp />
        <div className="content">
          <SearchInput
            resultsPerPage={resultsPerPage}
            handleNewResultPreference={handleNewResultPreference}
          />
          <SearchResults resultsPerPage={resultsPerPage} search={searchContent} />
          <SearchCTA />
          <Announcement version="4" />
          <Footer />
        </div>
      </section>
    </main>
  );
};

export default Search;

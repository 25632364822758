import { useRef, useState, useContext } from "react";
import { EasyModalContext } from "../contexts";
import { NotificationContext } from "../contexts";
import "./css/easyModal.css";

export const EasyModalProvider = ({ children }) => {
  const [modal, setModal] = useState({
    shown: false,
    type: null,
    title: null,
    content: null,
  });
  const modalEl = useRef(null);
  const { createNotification } = useContext(NotificationContext);

  const createModal = (type, title, content) => {
    setModal({ shown: true, type, title, content });
  };

  const closeModal = () => {
    modalEl.current.classList.add("hide");
    setTimeout(() => {
      setModal({ shown: false, type: null, title: null, content: null });
    }, 300);
  };

  const copyNumberToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    createNotification('green', 'Copied', 'Number copied to clipboard', 'bx bx-copy');
  };

  const handleCallPerson = (number) => {
    const a = document.createElement("a");
    a.href = `tel:${number}`;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    createNotification('green', 'Calling', `Calling ${number}`, 'bx bx-phone');
  }

  return (
    <EasyModalContext.Provider value={{ createModal, closeModal }}>
      {children}
      {modal.shown ? (
        <div ref={modalEl} className="container modal">
          <div className="container">
            <button onClick={closeModal} className="close">
              <i className="bx bx-x"></i>
            </button>
            <header>
              <h1>{modal.title}</h1>
            </header>
            <div className="content">
              {modal.type === "phone" ? (
                <ul className="phone">
                  {Object.keys(modal.content).map((key) => (
                    <li key={key}>
                      <div className="left">
                        <h2>{modal.content[key].title}</h2>
                        <a onClick={() => copyNumberToClipboard(modal.content[key].aNumber)}>
                          Number:
                          <span> {modal.content[key].number}</span>
                        </a>
                        <p><span>Availability: </span>{modal.content[key].avail}</p>
                      </div>
                      <div>
                        <button onClick={() => handleCallPerson(modal.content[key].aNumber)}>
                            <i className="bx bx-phone" />
                            <p>Call</p>
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </EasyModalContext.Provider>
  );
};

import React from "react";
import { LightboxContext, NotificationContext } from "../../../contexts";
import "./Container.css";
import { HomeAbt1 } from "../../../assets";

const HomeAbout = () => {
  const { createLightboxImage } = React.useContext(LightboxContext);
  const { useNotificationPreset } = React.useContext(NotificationContext);

  return (
    <div className="container homeabout">
      <div className="left">
        <h2>Supporting Recovery and Rebuilding Lives</h2>
        <img
          src={HomeAbt1}
          alt="Stuff"
          onClick={(e) => createLightboxImage(e.target.src, e.target.alt)}
        />
        <p>
          Our mission is to provide resources for individuals and families
          impacted by substance use disorder, and to build community awareness
          and hope, while focusing on finding and increasing pathways to
          recovery. CCPR’s hope is to change the conversation concerning
          substance use from one of shame, isolation, and judgment to one of
          collaboration, respect, and honoring of the individuals and families
          engaged in this struggle.
        </p>
        <div className="blurbs">
          <div className="blurb">
            <h3>Our Mission</h3>
            <p>
              To provide comprehensive support and resources to individuals and families
              recovering from substance use disorder.
            </p>
          </div>
          <div className="blurb">
            <h3>Our Vision</h3>
            <p>
              A community where everyone has the opportunity to thrive and lead
              a fulfilling life.
            </p>
          </div>
        </div>
        <div className="buttons">
          <a href="/about" className="button">
            Learn More
          </a>
          <a
            className="link"
            onClick={(e) => {
              e.preventDefault();
              useNotificationPreset("construction");
            }}
            href="/volunteer"
          >
            Get Involved
            <i className="bx bx-chevron-right" />
          </a>
        </div>
      </div>
      <div className="right">
        <img
          src="https://mail.google.com/mail/u/0?ui=2&ik=b9ec579cba&attid=0.3&permmsgid=msg-f:1794511666257315629&th=18e762da4c8fc72d&view=fimg&realattid=f_lu73acnd0&disp=thd&attbid=ANGjdJ_1YtliAI55BLGafI-c-ch4Uyb_R9kgQVbM4DEV_qTdN6F22WCincgFYq23WV_b6RGAzOtsCCNvyiEF-pFFzwL5uKELwtQ12qfXwTaGR_GauBHsN-kvxiqoNDQ&ats=2524608000000&sz=w1920-h911"
          alt="Stuff"
          onClick={(e) => createLightboxImage(e.target.src, e.target.alt)}
        />
      </div>
    </div>
  );
};

export default HomeAbout;

import React, { Suspense,  } from "react";
import { Button } from "../../../components";
import "./Container.css";

const RCHero = () => {
  return (
    <div className="container rchero">
      <h1>Welcome to the Recovery Center</h1>
      <p>Discover a supportive community for your recovery journey.</p>
      <div className="buttons">
        <Button
          text="Take the Tour"
          hover={true}
          background="#77509F"
          color="#fff"
          onClick={() => {
            const container = document.querySelector('.container .rtour');
            container.scrollIntoView({ behavior: 'smooth' });
          }}
        />
        <Button
          text="Get Directions"
          border={"1px solid #77509F"}
          inverse={true}
          background="transparent"
          color="#fff"
          onClick={() => {
            const container = document.querySelector('.container .rccontact');
            container.scrollIntoView({ behavior: 'smooth' });
          }}
        />
      </div>
    </div>
  );
};

export default RCHero;

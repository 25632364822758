import React from 'react'
import './Container.css'

const Container = () => {
  return (
    <div className='container divider'>
      <div className="divide"></div>
    </div>
  )
}

export default Container

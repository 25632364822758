import React from "react";
import { CCPR, RCBG2, HomeBG2 } from "../../../assets";
import { LightboxContext, NotificationContext } from "../../../contexts";
import { useNavigate } from "react-router-dom";
import "./Container.css";

const Navbar = ({ location, transparent, color }) => {
  const [menuToggle, setMenuToggle] = React.useState(false);
  const [subMenuSlide, setSubMenuSlide] = React.useState(1);
  const [query, setQuery] = React.useState("");
  const [events, setEvents] = React.useState([]);
  const navbar = React.useRef(null);
  const navigate = useNavigate();
  const { createLightboxImage } = React.useContext(LightboxContext);
  const { createNotification } = React.useContext(NotificationContext);

  React.useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (
        currentScrollPos > prevScrollPos &&
        currentScrollPos > 160 &&
        !menuToggle
      ) {
        navbar.current.classList.add("scrolled");
        setMenuToggle(false);
        navbar.current.classList.remove("home");
        navbar.current.parentElement.classList.remove("expanded");
      } else if (currentScrollPos < 80 && !menuToggle) {
        navbar.current.classList.remove("scrolled");
        navbar.current.classList.add("home");
        navbar.current.parentElement.classList.add("expanded");
      } else if (!menuToggle) {
        setMenuToggle(false);
        navbar.current.classList.remove("scrolled");
        navbar.current.classList.remove("home");
        navbar.current.parentElement.classList.remove("expanded");
      }

      prevScrollPos = currentScrollPos;
    };

    const handleResize = () => {
      const clientWidth = document.body.clientWidth;
      if (clientWidth > 875) {
        setMenuToggle(false);
      }
    };

    handleScroll();
    handleResize();

    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    // Fetch Data
    setTimeout(() => {
      const topEventsArr = [
        {
          id: 5,
          title: "Event 1 Title",
          status: "completed",
          icon: "bx-check",
          date: "12/12/2021",
          time: "10:00AM - 12:00PM",
          location: {
            name: "1 Taconic Place, Chatham, NY 12037",
            link: "https://www.google.com/maps",
          },
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
          links: [
            {
              name: "Link 1",
              link: "https://www.google.com",
            },
            {
              name: "Link 2",
              link: "https://www.google.com",
            },
            {
              name: "Link 3",
              link: "https://www.google.com",
            },
          ],
          images: [
            {
              name: "Image 1",
              description: "Image 1 description",
              link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
            },
            {
              name: "Image 2",
              description: "Image 2 description",
              link: "https://www.google.com",
            },
            {
              name: "Image 3",
              description: "Image 3 description",
              link: "https://www.google.com",
            },
          ],
          thumbnail: {
            src: RCBG2,
            alt: "Event 1",
          },
          tags: ["Support", "Meeting", "Free Food"],
        },
        {
          id: 4,
          title: "Event 1 Title",
          status: "completed",
          icon: "bx-check",
          date: "12/12/2021",
          time: "10:00AM - 12:00PM",
          location: {
            name: "Recovery Center",
            link: "https://www.google.com/maps",
          },
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
          links: [
            {
              name: "Link 1",
              link: "https://www.google.com",
            },
            {
              name: "Link 2",
              link: "https://www.google.com",
            },
            {
              name: "Link 3",
              link: "https://www.google.com",
            },
          ],
          images: [
            {
              name: "Image 1",
              description: "Image 1 description",
              link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
            },
            {
              name: "Image 2",
              description: "Image 2 description",
              link: "https://www.google.com",
            },
            {
              name: "Image 3",
              description: "Image 3 description",
              link: "https://www.google.com",
            },
          ],
          thumbnail: {
            src: HomeBG2,
            alt: "Event 1",
          },
          tags: ["Support", "Meeting", "Free Food"],
        },
      ];

      setEvents(topEventsArr);
    }, 1200);
  }, []);

  React.useEffect(() => {
    if (menuToggle) {
      document.body.style.overflow = "hidden";
      const view = document.querySelector(`.view.${location}`);
      if (view) {
        view.classList.add("menu-open");
      }
    } else {
      const view = document.querySelector(`.view.${location}`);
      if (view) {
        view.classList.remove("menu-open");
      }
      setTimeout(() => {
        document.body.style.overflow = "auto";
      }, 300);
    }
  }, [location, menuToggle]);

  async function handleSearch() {
    if (!query)
      return createNotification(
        "red",
        "Unable to Search",
        "Please enter a search query",
        "bx-x",
        3000
      );

    window.location.href = `/search/${query}`;
  }

  return (
    <nav
      ref={navbar}
      className={`container navbar ${transparent ? "transparent" : ""} ${
        color === "#fff" ? "white" : "black"
      }`}
    >
      <div
        style={{
          background: `${transparent ? `transparent` : "#ebebeb"}`,
          color: `${color ? color : "#000"}`,
        }}
        className={`wrapper ${menuToggle ? "open" : "closed"}`}
      >
        <Menu setMenuToggle={setMenuToggle} menuToggle={menuToggle} />
        <NavbarLeft events={events} createLightboxImage={createLightboxImage} />
        <NavbarRight
          handleSearch={handleSearch}
          query={query}
          setQuery={setQuery}
          navigate={navigate}
        />
        <MobileMenu
          setMenuToggle={setMenuToggle}
          setQuery={setQuery}
          query={query}
          subMenuSlide={subMenuSlide}
          setSubMenuSlide={setSubMenuSlide}
          createLightboxImage={createLightboxImage}
          events={events}
        />
      </div>
    </nav>
  );
};

const Menu = ({ setMenuToggle, menuToggle }) => {
  return (
    <div onClick={() => setMenuToggle(!menuToggle)} className="menu">
      <i className={`bx ${menuToggle ? "bx-x" : "bx-menu-alt-left"}`} />
    </div>
  );
};

const NavbarLeft = ({ createLightboxImage, events }) => {
  return (
    <div className="left">
      <a href="/" className="logo">
        <img src={CCPR} alt="Logo" />
      </a>
      <ul className="nav">
        <li className="sub-li">
          <a
            onClick={(e) => e.target.parentElement.classList.toggle("open")}
            className="title"
          >
            About Us and More
            <i className="bx bx-chevron-down" />
          </a>
          <SubMenu events={events} createLightboxImage={createLightboxImage} />
        </li>
        <li>
          <a
            className={
              window.location.pathname === "/help-line" ? "active" : ""
            }
            href="/help-line"
          >
            Help Line
          </a>
        </li>
        <li>
          <a
            className={window.location.pathname === "/calendar" ? "active" : ""}
            href="/calendar"
          >
            Calendar
          </a>
        </li>
        <li>
          <a
            className={
              window.location.pathname === "/recovery-center" ? "active" : ""
            }
            href="/recovery-center"
          >
            Recovery Center
          </a>
        </li>
      </ul>
    </div>
  );
};

const NavbarRight = ({ query, setQuery, navigate, handleSearch }) => {
  return (
    <div className="right">
      <div className="buttons">
        <a href="/donate">Dontate</a>
        <a href="/contact">Contact</a>
      </div>
      <Search query={query} setQuery={setQuery} handleSearch={handleSearch} />
      <i
        role="link"
        aria-label="Login Link"
        onClick={() => navigate("/login")}
        className="bx bx-user user"
      />
    </div>
  );
};

const Search = ({ query, setQuery, handleSearch }) => {
  return (
    <div className="search">
      <i
        role="link"
        aria-label="Search Button"
        className="bx bx-search icon"
        onClick={() => handleSearch()}
      />
      <input
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        type="text"
        placeholder="Search..."
      />
    </div>
  );
};

const MobileMenu = ({
  setMenuToggle,
  subMenuSlide,
  setSubMenuSlide,
  setQuery,
  query,
  createLightboxImage,
  events,
}) => {
  return (
    <div className="mobile">
      <div
        className={`wrapper ${
          subMenuSlide === 1
            ? "first"
            : subMenuSlide === 2
            ? "second"
            : subMenuSlide === 3
            ? "third"
            : subMenuSlide === 4
            ? "fourth"
            : ""
        }`}
      >
        <div className="one">
          <a
            onClick={(e) => {
              e.preventDefault();
              if (
                window.location.pathname === "/" ||
                window.location.pathname === "/home"
              ) {
                setMenuToggle(false);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                window.location.href = "/";
              }
            }}
            href="/"
          >
            <img src={CCPR} alt="Columbia County Patways to Recovery Logo" />
          </a>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li className="sub-li">
              <a onClick={() => setSubMenuSlide(2)} className="title">
                About Us and More
              </a>
              <i className="bx bx-chevron-right" />
            </li>
            <li>
              <a href="/help-line">Help Line</a>
            </li>
            <li>
              <a href="/calendar">Calendar</a>
            </li>
            <li>
              <a href="/recovery-center">Recovery Center</a>
            </li>
          </ul>
          <div className="buttons">
            <a href="/donate">Dontate</a>
            <a href="/contact">Contact</a>
          </div>
          <div className="search">
            <i
              role="link"
              aria-label="Search Button"
              className="bx bx-search icon"
              onClick={() => handleSearch()}
            />
            <input
              type="text"
              placeholder="Search..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          {events.slice(0, 1).map((event, index) => (
            <div key={index} className="event">
              <img 
                src={event.thumbnail.src} 
                alt={event.thumbnail.alt}
                onClick={(e) => createLightboxImage(event.thumbnail.src, event.title)}
              />
              <h3>{event.title}</h3>
              <p>
                {event.description}
              </p>
              <a href={`/calendar?event=${event.date}`}>Event Link</a>
            </div>
          ))}
        </div>
        <div className="two">
          <ul>
            <li onClick={() => setSubMenuSlide(1)} className="sub-li">
              <i className="bx bx-chevron-left" />
              <a className="title">Back</a>
            </li>
            <li className="sub-li">
              <a onClick={() => setSubMenuSlide(3)} className="title">
                About Us
              </a>
              <i className="bx bx-chevron-right" />
            </li>
            <li className="sub-li">
              <a onClick={() => setSubMenuSlide(4)} className="title">
                Resources
              </a>
              <i className="bx bx-chevron-right" />
            </li>
          </ul>
        </div>
        <div className="three">
          <ul>
            <li onClick={() => setSubMenuSlide(2)} className="sub-li">
              <i className="bx bx-chevron-left" />
              <a className="title">Back</a>
            </li>
            <li>
              <a href="">About CCPR</a>
            </li>
            <li>
              <a href="">Board of Directors</a>
            </li>
            <li>
              <a href="">Committees</a>
            </li>
            <li>
              <a href="">Meetings</a>
            </li>
          </ul>
        </div>
        <div className="four">
          <ul>
            <li onClick={() => setSubMenuSlide(2)} className="sub-li">
              <i className="bx bx-chevron-left" />
              <a className="title">Back</a>
            </li>
            <li>
              <a href="">Lorem Ipsum</a>
            </li>
            <li>
              <a href="">Lorem Ipsum</a>
            </li>
            <li>
              <a href="">Lorem Ipsum</a>
            </li>
            <li>
              <a href="">Lorem Ipsum</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const SubMenu = ({ createLightboxImage, events }) => {
  const SubMenuLeft = () => {
    return (
      <div className="left">
        <div className="col">
          <header>About Us</header>
          <ul>
            <li>
              <a
                href="/about"
                className={`sub ${
                  window.location.pathname === "/about" ? "active" : ""
                }`}
              >
                <div className="left">
                  <i className="bx bxs-group" />
                </div>
                <div className="right">
                  <h2>About CCPR</h2>
                  <p>Learn about Columbia Pathways to Recovery</p>
                </div>
              </a>
            </li>
            <li>
              <a
                href="/board-of-directors"
                className={`sub ${
                  window.location.pathname === "/board-of-directors"
                    ? "active"
                    : ""
                }`}
              >
                <div className="left">
                  <i className="bx bx-star" />
                </div>
                <div className="right">
                  <h2>Board of Directors</h2>
                  <p>Meet the CCPR Board</p>
                </div>
              </a>
            </li>
            <li>
              <a
                href="/committees"
                className={`sub ${
                  window.location.pathname === "/committees" ? "active" : ""
                }`}
              >
                <div className="left">
                  <i className="bx bx-collection" />
                </div>
                <div className="right">
                  <h2>Committees</h2>
                  <p>See what committees CCPR has</p>
                </div>
              </a>
            </li>
            <li>
              <a
                href="/meetings"
                className={`sub ${
                  window.location.pathname === "/meetings" ? "active" : ""
                }`}
              >
                <div className="left">
                  <i className="bx bxs-bolt" />
                </div>
                <div className="right">
                  <h2>Meetings</h2>
                  <p>See a list of meetings</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div className="col">
          <header>Resources</header>
          <ul>
            <li>
              <a href="" className="sub">
                <div className="left">
                  <i className="bx bxs-group" />
                </div>
                <div className="right">
                  <h2>Lorem Ipsum</h2>
                  <p>Lorem Ipsum Dolor</p>
                </div>
              </a>
            </li>
            <li>
              <a href="" className="sub">
                <div className="left">
                  <i className="bx bx-star" />
                </div>
                <div className="right">
                  <h2>Lorem Ipsum</h2>
                  <p>Lorem Ipsum Dolor</p>
                </div>
              </a>
            </li>
            <li>
              <a href="" className="sub">
                <div className="left">
                  <i className="bx bx-collection" />
                </div>
                <div className="right">
                  <h2>Lorem Ipsum</h2>
                  <p>Lorem Ipsum Dolor</p>
                </div>
              </a>
            </li>
            <li>
              <a href="" className="sub">
                <div className="left">
                  <i className="bx bxs-bolt" />
                </div>
                <div className="right">
                  <h2>Lorem Ipsum</h2>
                  <p>Lorem Ipsum Dolor</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const SubMenuRight = () => {
    return (
      <div className="right">
        <header>Events</header>
        <div className="events">
          {events.map((event, index) => (
            <div key={index} className="event">
              <div className="left">
                <img
                  src={event.thumbnail.src}
                  alt={event.thumbnail.alt}
                  onClick={(e) =>
                    createLightboxImage(event.thumbnail.src, event.title)
                  }
                />
              </div>
              <div className="right">
                <h2>{event.title}</h2>
                <p>{event.description}</p>
                <a href={`/calendar?event=${event.date}`}>
                  Event Link
                </a>
              </div>
            </div>
          ))}
        </div>
        <a href="/calendar">
          <p>View All Events</p>
          <i className="bx bx-chevron-right" />
        </a>
      </div>
    );
  };

  return (
    <ul className="sub-menu">
      <SubMenuLeft />
      <SubMenuRight />
    </ul>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import {
  NotificationContext,
  AlertContext,
  LightboxContext,
} from "../../../contexts";
import "./Container.css";
import { CircularProgress, Pagination } from "@mui/material";
import { HomeBG } from "../../../assets";

const CalCal = ({ externalEvent }) => {
  const [currentDate] = useState(new Date());
  const [viewDate, setViewDate] = useState(new Date());
  const [activeDate, setActiveDate] = useState(null);
  const [days, setDays] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [eventsArr, setEventsArr] = useState([]);
  const [eventsPage, setEventsPage] = useState(1);
  const [event, setEvent] = useState({
    shown: false,
    secondShown: false,
    data: null,
  });
  const { createLightboxImage } = React.useContext(LightboxContext);
  const { createNotification } = React.useContext(NotificationContext);
  const { createAlert, copyToClipboard } = React.useContext(AlertContext);

  useEffect(() => {
    function initCalendar() {
      const firstDay = new Date(viewDate.getFullYear(), viewDate.getMonth(), 1);
      const lastDay = new Date(
        viewDate.getFullYear(),
        viewDate.getMonth() + 1,
        0
      );
      const prevLastDay = new Date(
        viewDate.getFullYear(),
        viewDate.getMonth(),
        0
      );

      const prevDays = prevLastDay.getDate();
      const lastDate = lastDay.getDate();
      const firstDayIndex = firstDay.getDay();
      const lastDayIndex = lastDay.getDay();
      const nextDays = 7 - lastDayIndex - 1;

      let daysArr = [];

      // Previous month days
      for (let x = firstDayIndex; x > 0; x--) {
        const prevMonth = viewDate.getMonth() - 1;
        const year =
          prevMonth < 0 ? viewDate.getFullYear() - 1 : viewDate.getFullYear();
        daysArr.push({
          day: prevDays - x + 1,
          month: (viewDate.getMonth() - 1 + 12) % 12,
          year: year,
          class: "prev-date",
        });
      }

      // Current month days
      for (let i = 1; i <= lastDate; i++) {
        const dayHasEvent = eventsArr.some(
          (event) =>
            event.day === i &&
            event.month === viewDate.getMonth() &&
            event.year === viewDate.getFullYear()
        );

        daysArr.push({
          day: i,
          month: viewDate.getMonth(),
          year: viewDate.getFullYear(),
          class: dayHasEvent ? "event" : "",
        });
      }

      // Next month days
      for (let j = 1; j <= nextDays; j++) {
        const nextMonth = viewDate.getMonth() + 1;
        const year =
          nextMonth > 11 ? viewDate.getFullYear() + 1 : viewDate.getFullYear();
        daysArr.push({
          day: j,
          month: (viewDate.getMonth() + 1) % 12,
          year: year,
          class: "next-date",
        });
      }

      setDays(daysArr);
    }

    initCalendar();
  }, [viewDate, eventsArr]);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString(undefined, options);
  };

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");

    if (value.length > 2 && value.charAt(2) !== "/") {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }

    if (value.length > 7) {
      value = value.slice(0, 7);
    }

    setInputValue(value);
  };

  const handleGoClick = () => {
    const [month, year] = inputValue.split("/");
    if (month && year && month <= 12 && year.length === 4) {
      setViewDate(new Date(year, month - 1, 1));
    } else {
      return createNotification(
        "red",
        "Error",
        "Invalid date format",
        "bx-x",
        3000
      );
    }
  };

  useEffect(() => {
    setLoading(true);

    const query = new URLSearchParams(window.location.search);
    const eventDay = query.get("event");
    if(eventDay) {
      const dateObject = new Date(eventDay);
      if(!isNaN(dateObject.getTime())) {
        setViewDate(dateObject);
        setActiveDate({
          day: dateObject.getDate(),
          month: dateObject.getMonth(),
          year: dateObject.getFullYear(),
          class: "",
        });
        setEventsPage(1);
        const calendarContainer = document.querySelector(".container.calcal");
        if(calendarContainer) {
          calendarContainer.scrollIntoView({ behavior: "smooth" });
        }
        window.history.replaceState({}, document.title, window.location.pathname);
      } else {
        console.error("Invalid Date:", eventDay);
      }
    }

    setTimeout(() => {
      const Arr = [
        {
          day: 13,
          month: 5,
          year: 2024,
          events: [
            {
              id: 1,
              title: "Event 1 Title",
              status: "completed",
              icon: "bx-check",
              date: "12/12/2021",
              time: "10:00AM - 12:00PM",
              location: {
                name: "1 Taconic Place, Chatham, NY 12037",
                link: "https://www.google.com/maps",
              },
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
              links: [
                {
                  name: "Link 1",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 2",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 3",
                  link: "https://www.google.com",
                },
              ],
              images: [
                {
                  name: "Image 1",
                  description: "Image 1 description",
                  link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
                },
                {
                  name: "Image 2",
                  description: "Image 2 description",
                  link: "https://www.google.com",
                },
                {
                  name: "Image 3",
                  description: "Image 3 description",
                  link: "https://www.google.com",
                },
              ],
            },
            {
              id: 2,
              title: "Event 1 Title",
              status: "completed",
              icon: "bx-check",
              date: "12/12/2021",
              time: "10:00AM - 12:00PM",
              location: {
                name: "1 Taconic Place, Chatham, NY 12037",
                link: "https://www.google.com/maps",
              },
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
              links: [
                {
                  name: "Link 1",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 2",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 3",
                  link: "https://www.google.com",
                },
              ],
              images: [
                {
                  name: "Image 1",
                  description: "Image 1 description",
                  link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
                },
                {
                  name: "Image 2",
                  description: "Image 2 description",
                  link: "https://www.google.com",
                },
                {
                  name: "Image 3",
                  description: "Image 3 description",
                  link: "https://www.google.com",
                },
              ],
            },
          ],
        },
        {
          day: 15,
          month: 5,
          year: 2024,
          events: [
            {
              id: 3,
              title: "Event 1 Title",
              status: "completed",
              icon: "bx-check",
              date: "12/12/2021",
              time: "10:00AM - 12:00PM",
              location: {
                name: "1 Taconic Place, Chatham, NY 12037",
                link: "https://www.google.com/maps",
              },
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
              links: [
                {
                  name: "Link 1",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 2",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 3",
                  link: "https://www.google.com",
                },
              ],
              images: [
                {
                  name: "Image 1",
                  description: "Image 1 description",
                  link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
                },
                {
                  name: "Image 2",
                  description: "Image 2 description",
                  link: "https://www.google.com",
                },
                {
                  name: "Image 3",
                  description: "Image 3 description",
                  link: "https://www.google.com",
                },
              ],
            },
            {
              id: 4,
              title: "Event 1 Title",
              status: "completed",
              icon: "bx-check",
              date: "12/12/2021",
              time: "10:00AM - 12:00PM",
              location: {
                name: "1 Taconic Place, Chatham, NY 12037",
                link: "https://www.google.com/maps",
              },
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
              links: [
                {
                  name: "Link 1",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 2",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 3",
                  link: "https://www.google.com",
                },
              ],
              images: [
                {
                  name: "Image 1",
                  description: "Image 1 description",
                  link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
                },
                {
                  name: "Image 2",
                  description: "Image 2 description",
                  link: "https://www.google.com",
                },
                {
                  name: "Image 3",
                  description: "Image 3 description",
                  link: "https://www.google.com",
                },
              ],
            },
            {
              id: 5,
              title: "Event 1 Title",
              status: "completed",
              icon: "bx-check",
              date: "12/12/2021",
              time: "10:00AM - 12:00PM",
              location: {
                name: "1 Taconic Place, Chatham, NY 12037",
                link: "https://www.google.com/maps",
              },
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
              links: [
                {
                  name: "Link 1",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 2",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 3",
                  link: "https://www.google.com",
                },
              ],
              images: [
                {
                  name: "Image 1",
                  description: "Image 1 description",
                  link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
                },
                {
                  name: "Image 2",
                  description: "Image 2 description",
                  link: "https://www.google.com",
                },
                {
                  name: "Image 3",
                  description: "Image 3 description",
                  link: "https://www.google.com",
                },
              ],
            },
            {
              id: 6,
              title: "Event 1 Title",
              status: "completed",
              icon: "bx-check",
              date: "12/12/2021",
              time: "10:00AM - 12:00PM",
              location: {
                name: "1 Taconic Place, Chatham, NY 12037",
                link: "https://www.google.com/maps",
              },
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
              links: [
                {
                  name: "Link 1",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 2",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 3",
                  link: "https://www.google.com",
                },
              ],
              images: [
                {
                  name: "Image 1",
                  description: "Image 1 description",
                  link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
                },
                {
                  name: "Image 2",
                  description: "Image 2 description",
                  link: "https://www.google.com",
                },
                {
                  name: "Image 3",
                  description: "Image 3 description",
                  link: "https://www.google.com",
                },
              ],
            },
            {
              id: 7,
              title: "Event 1 Title",
              status: "canceled",
              icon: "bx-x",
              date: "12/12/2021",
              time: "10:00AM - 12:00PM",
              location: {
                name: "1 Taconic Place, Chatham, NY 12037",
                link: "https://www.google.com/maps",
              },
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
              links: [
                {
                  name: "Link 1",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 2",
                  link: "https://www.google.com",
                },
                {
                  name: "Link 3",
                  link: "https://www.google.com",
                },
              ],
              images: [
                {
                  name: "Image 1",
                  description: "Image 1 description",
                  link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
                },
                {
                  name: "Image 2",
                  description: "Image 2 description",
                  link: "https://www.google.com",
                },
                {
                  name: "Image 3",
                  description: "Image 3 description",
                  link: "https://www.google.com",
                },
              ],
            },
          ],
        },
      ];
      setEventsArr(Arr);
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (externalEvent) {
      const date = new Date(externalEvent);
      if (!isNaN(date.getTime())) {
        setViewDate(date);
        setActiveDate({
          day: date.getDate(),
          month: date.getMonth(),
          year: date.getFullYear(),
          class: "",
        });
        document.querySelector(".event-page").style.animation =
          "slide-right-out 0.3s ease-in-out forwards";
        setTimeout(() => {
          setEvent({ shown: false, secondShown: false });
          document.getElementById("page-right").style.display = "block";
          document.querySelector(".event-page").style.animation = "none";
          document.getElementById("page-right").style.display = "block";
          setTimeout(() => {
            document.getElementById("page-right").style.transform =
              "translateX(0)";
            document.getElementById("page-right").style.opacity = "1";
            document.getElementById("page-right").style.filter = "blur(0)";
          }, 100);
        }, 300);
        setEventsPage(1);
      } else {
        console.error("Invalid Date:", externalEvent);
      }
    }
  }, [externalEvent]);

  const getEventsForActiveDate = () => {
    if (!activeDate) return [];

    const eventForDate = eventsArr.find(
      (event) =>
        event.day === activeDate.day &&
        event.month === activeDate.month &&
        event.year === activeDate.year
    );

    return eventForDate ? eventForDate.events : [];
  };

  const eventsForActiveDate = getEventsForActiveDate();

  const handlePageChange = (event, value) => {
    setEventsPage(value);
  };

  const eventsPerPage = 5;
  const pageCount = Math.ceil(eventsForActiveDate.length / eventsPerPage);
  const displayedEvents = eventsForActiveDate.slice(
    (eventsPage - 1) * eventsPerPage,
    eventsPage * eventsPerPage
  );

  return (
    <div className="container calcal">
      <div className="wrapper">
        <div className="left">
          <div className="calendar">
            <div className="month">
              <i
                className="bx bx-chevron-left prev"
                onClick={() =>
                  setViewDate(
                    new Date(viewDate.getFullYear(), viewDate.getMonth() - 1, 1)
                  )
                }
              />
              <div className="date">{formatDate(viewDate)}</div>
              <i
                className="bx bx-chevron-right next"
                onClick={() =>
                  setViewDate(
                    new Date(viewDate.getFullYear(), viewDate.getMonth() + 1, 1)
                  )
                }
              />
            </div>
            <div className="weekdays">
              <div>sun</div>
              <div>mon</div>
              <div>tue</div>
              <div>wed</div>
              <div>thu</div>
              <div>fri</div>
              <div>sat</div>
            </div>
            <div className="days">
              {days.map((dayObj, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (dayObj.class === "prev-date") {
                      setViewDate(
                        new Date(
                          viewDate.getFullYear(),
                          viewDate.getMonth() - 1,
                          1
                        )
                      );
                    } else if (dayObj.class === "next-date") {
                      setViewDate(
                        new Date(
                          viewDate.getFullYear(),
                          viewDate.getMonth() + 1,
                          1
                        )
                      );
                    }
                    document.querySelector(".event-page").style.animation =
                      "slide-right-out 0.3s ease-in-out forwards";
                    setTimeout(() => {
                      setEvent({ shown: false, secondShown: false });
                      document.getElementById("page-right").style.display =
                        "block";
                      document.querySelector(".event-page").style.animation =
                        "none";
                      document.getElementById("page-right").style.display =
                        "block";
                      setTimeout(() => {
                        document.getElementById("page-right").style.transform =
                          "translateX(0)";
                        document.getElementById("page-right").style.opacity =
                          "1";
                        document.getElementById("page-right").style.filter =
                          "blur(0)";
                      }, 100);
                    }, 300);
                    setActiveDate(dayObj);
                    setEventsPage(1);
                  }}
                  className={`day ${dayObj?.class} ${
                    dayObj.day === currentDate.getDate() &&
                    dayObj.month === currentDate.getMonth() &&
                    dayObj.year === currentDate.getFullYear() &&
                    !dayObj.class
                      ? "today"
                      : ""
                  } ${
                    dayObj.day === activeDate?.day &&
                    dayObj.month === activeDate?.month &&
                    dayObj.year === activeDate?.year
                      ? "active"
                      : ""
                  }`}
                >
                  {dayObj.day}
                </div>
              ))}
            </div>
            <div className="goto-today">
              <div className="goto">
                <input
                  type="text"
                  placeholder="mm/yyyy"
                  className="date-input"
                  value={inputValue}
                  onChange={handleInputChange}
                />
                <button className="goto-btn" onClick={handleGoClick}>
                  Go
                </button>
              </div>
              <button
                className="today"
                onClick={() => {
                  document.querySelector(".event-page").style.animation =
                    "slide-right-out 0.3s ease-in-out forwards";
                  setTimeout(() => {
                    setEvent({ shown: false, secondShown: false });
                    document.getElementById("page-right").style.display =
                      "block";
                    document.querySelector(".event-page").style.animation =
                      "none";
                    document.getElementById("page-right").style.display =
                      "block";
                    setTimeout(() => {
                      document.getElementById("page-right").style.transform =
                        "translateX(0)";
                      document.getElementById("page-right").style.opacity = "1";
                      document.getElementById("page-right").style.filter =
                        "blur(0)";
                    }, 100);
                  }, 300);
                  const today = new Date();
                  setViewDate(today);
                  setActiveDate({
                    day: today.getDate(),
                    month: today.getMonth(),
                    year: today.getFullYear(),
                    class: "",
                  });
                }}
              >
                Today
              </button>
            </div>
          </div>
        </div>
        <div
          id="page-right"
          className={`right  ${event.shown ? "hidden" : ""}`}
        >
          <div className="today-date">
            {activeDate ? (
              <>
                <div className="event-day">
                  {new Date(
                    activeDate.year,
                    activeDate.month,
                    activeDate.day
                  ).toLocaleDateString(undefined, { weekday: "long" })}
                </div>
                <div className="event-date">
                  {new Date(
                    activeDate.year,
                    activeDate.month,
                    activeDate.day
                  ).toLocaleDateString(undefined, {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </div>
              </>
            ) : (
              <>
                <div className="event-day">
                  {currentDate.toLocaleDateString(undefined, {
                    weekday: "long",
                  })}
                </div>
                <div className="event-date">
                  {currentDate.toLocaleDateString(undefined, {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </div>
              </>
            )}
          </div>
          <div className={`events`}>
            {loading ? (
              <div
                style={{
                  color: "#77509F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                }}
                className="result"
              >
                <CircularProgress color="inherit" />
              </div>
            ) : eventsForActiveDate && eventsForActiveDate.length > 0 ? (
              <>
                {displayedEvents.map((eventItem, index) => (
                  <div
                    onClick={() => {
                      setEvent({ shown: true, data: eventItem });
                      document.getElementById("page-right").style.transform =
                        "translateX(-50%)";
                      document.getElementById("page-right").style.opacity = "0";
                      document.getElementById("page-right").style.filter =
                        "blur(0.25rem)";
                      setTimeout(() => {
                        document.getElementById("page-right").style.display =
                          "none";
                        document.querySelector(".event-page").style.animation =
                          "slideInFromRight 0.3s ease-in-out";
                        setEvent((prevEvent) => ({
                          ...prevEvent,
                          secondShown: true,
                        }));
                      }, 300);
                    }}
                    id={eventItem.id}
                    className="event"
                    key={index}
                  >
                    <div className="title">
                      <i className="bx bx-circle" />
                      <h3 className="event-title">{eventItem.title}</h3>
                    </div>
                    <div className="event-time">{eventItem.time}</div>
                    <div className={`event-status ${eventItem.status}`}>
                      <i className={`bx ${eventItem.icon}`} />
                      <div className="tooltip">{eventItem.status}</div>
                    </div>
                  </div>
                ))}
                <Pagination
                  className="pagination"
                  variant="outlined"
                  color="secondary"
                  style={{
                    color: "#fff",
                    fontFamily: "Red Hat Display, sans-serif",
                  }}
                  count={pageCount}
                  page={eventsPage}
                  onChange={handlePageChange}
                />
              </>
            ) : (
              <div
                style={{
                  color: "#77509F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
                className="result"
              >
                <div
                  style={{
                    color: "#878787",
                    fontSize: "2rem",
                    fontWeight: "900",
                  }}
                >
                  No Events
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`event-page ${!event.secondShown ? "hidden" : ""}`}>
          <i
            className="bx bx-left-arrow-alt icon"
            onClick={() => {
              document.querySelector(".event-page").style.animation =
                "slide-right-out 0.3s ease-in-out forwards";
              setTimeout(() => {
                setEvent({ shown: false, secondShown: false });
                document.getElementById("page-right").style.display = "block";
                document.querySelector(".event-page").style.animation = "none";
                document.getElementById("page-right").style.transform =
                  "translateX(-50%)";
                document.getElementById("page-right").style.opacity = "0";
                document.getElementById("page-right").style.filter =
                  "blur(0.25rem)";
                document.getElementById("page-right").style.display = "block";
                setTimeout(() => {
                  document.getElementById("page-right").style.transform =
                    "translateX(0)";
                  document.getElementById("page-right").style.opacity = "1";
                  document.getElementById("page-right").style.filter =
                    "blur(0)";
                }, 100);
              }, 300);
            }}
          />
          <h2>{event.data ? event.data?.title : ""}</h2>
          <div className="info-container">
            <h4>Event Status: </h4>
            <p style={{ textTransform: "capitalize" }}>
              {event.data ? event.data?.status : ""}
            </p>
          </div>
          <div className="info-container">
            <h4>Event Date: </h4>
            <p>{event.data ? event.data?.date : ""}</p>
          </div>
          <div className="info-container">
            <h4>Event Time: </h4>
            <p>{event.data ? event.data?.time : ""}</p>
          </div>
          <div className="info-container">
            <h4>Event Location: </h4>
            <button
              onClick={() => {
                createAlert({
                  title: "Event Location",
                  message: "Lorem ",
                  buttons: {
                    1: {
                      text: "Copy",
                      onClick: () => {
                        copyToClipboard(event.data?.location.name, "Address");
                      },
                      close: true,
                    },
                    2: {
                      text: "Open in Maps",
                      onClick: () => {
                        const anchor = document.createElement("a");
                        anchor.href = event.data?.location.link;
                        anchor.target = "_blank";
                        anchor.click();
                      },
                      close: true,
                    },
                  },
                });
              }}
            >
              {event.data ? event.data?.location.name : ""}
            </button>
          </div>
          <div className="info-container wrap">
            <h4>Event Description: </h4>
            <p>{event.data ? event.data?.description : ""}</p>
          </div>
          <div className="info-container wrap">
            <h4>Event Links: </h4>
            {event.data && event.data.links.length > 0
              ? event.data.links.map((link, index) => (
                  <a
                    key={index}
                    href={link.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.name}
                  </a>
                ))
              : null}
          </div>
          <div className="info-container wrap">
            <h4>Event Images: </h4>
            {event.data && event.data.images.length > 0
              ? event.data.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.link}
                    alt={image.description}
                    onClick={(e) =>
                      createLightboxImage(
                        image.link,
                        image.name,
                        image.description
                      )
                    }
                  />
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalCal;

import React from "react";
import { galleryData } from "../../../assets/Gallery/data";
import "./Container.css";

const RCGallery = () => {
  const [galData, setGalData] = React.useState([]);

  React.useEffect(() => {
    const imgArray = galleryData;
    setGalData(imgArray);
  }, []);

  const handleNextSlide = () => {
    const slides = document.querySelectorAll(".slide");
    const slider = document.querySelector(".slider");
    slider.appendChild(slides[0]);
  };

  const handlePrevSlide = () => {
    const slides = document.querySelectorAll(".slide");
    const slider = document.querySelector(".slider");
    slider.prepend(slides[slides.length - 1]);
  }

  return (
    <div className="container gal">
      <div className="slider">
        {galData.map((item, index) => {
          const styleBundle = {
            backgroundImage: `url('${item.image}')`,
            color: `${item.color}`,
          };

          return (
            <div className="slide" id={item.id} key={index} style={styleBundle}>
              <div className="content">
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="buttons">
        <button onClick={handlePrevSlide}  className="prev"></button>
        <button onClick={handleNextSlide} className="next"></button>
      </div>
    </div>
  );
};

export default RCGallery;

import React from "react";
import { NotificationContext } from "../../../contexts";
import "./Container.css";

const Announcement = ({ version, border = false }) => {
  const [closeAnnouncement, setCloseAnnouncement] = React.useState({
    invisible: false,
    version: 0,
  });
  const { useNotificationPreset } = React.useContext(NotificationContext);

  React.useEffect(() => {
    if (closeAnnouncement.invisible === true) {
      const wrapper = document.querySelector(
        `#wrapper${closeAnnouncement.version}`
      );
      if (wrapper) {
        let height = wrapper.offsetHeight;
        let width = wrapper.offsetWidth;
        let duration = 400;
        let intervalDuration = 5;
        let heightDecrementValue = height / (duration / intervalDuration);
        let widthDecrementValue = width / (duration / intervalDuration);

        let interval = setInterval(() => {
          height -= heightDecrementValue;
          width -= widthDecrementValue;

          wrapper.style.height = `${height}px`;
          wrapper.style.width = `${width}px`;

          if (height <= 0 || width <= 0) {
            clearInterval(interval);
            wrapper.style.height = "0px";
            wrapper.style.width = "0px";
          }
        }, intervalDuration);

        setTimeout(() => {
          wrapper.style.border = "none";
          document.querySelector(
            `#wrapper${closeAnnouncement.version}`
          ).parentElement.style.padding = "0";
        }, duration + 200);
      } else {
        console.error("Wrapper not found");
      }
    }
  }, [closeAnnouncement]);

  return (
    <div
      className={`container announcement ${
        closeAnnouncement.version ? "hidden" : ""
      }`}
    >
      {version === "1" ? (
        <div
          style={{ border: border ? "1px solid #000" : "none" }}
          id="wrapper1"
          className="wrapper"
        >
          <div className="left">
            <div className="col">
              <i className="bx bx-chat icon" />
            </div>
            <div className="col">
              <h1>Stay informed with the latest updates</h1>
              <p>Follow us on our social media to stay up to date!</p>
            </div>
          </div>
          <div className="right">
            <a
              onClick={(e) => {
                e.preventDefault();
                useNotificationPreset("construction");
              }}
              href="/donate"
              className="button"
            >
              Donate
            </a>
            <ul>
              <li>
                <a
                  aria-label="Facebook Link"
                  target="_blank"
                  href="https://www.facebook.com/columbiapathwaystorecovery"
                >
                  <i className="bx bxl-facebook" />
                </a>
              </li>
              <li>
                <a
                  aria-label="LinkedIn Link"
                  href="https://www.linkedin.com/company/columbia-pathways-to-recovery-inc/"
                >
                  <i className="bx bxl-linkedin" />
                </a>
              </li>
              <li>
                <a
                  aria-label="Instagram Link"
                  href="https://www.instagram.com/columbia_pathways_to_recovery/"
                >
                  <i className="bx bxl-instagram" />
                </a>
              </li>
              <li>
                <a
                  aria-label="Youtube Link"
                  href="https://www.youtube.com/channel/UCCFTslw0Hrx2XoznB6hlKIQ"
                >
                  <i className="bx bxl-youtube" />
                </a>
              </li>
              <li>
                <a
                  aria-label="Close Announcement"
                  onClick={() =>
                    setCloseAnnouncement({ version: 1, invisible: true })
                  }
                >
                  <i className="icon bx bx-x" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      ) : version === "2" ? (
        <div
          style={{ border: border ? "1px solid #000" : "none" }}
          id="wrapper2"
          className="wrapper"
        >
          <div className="left">
            <div className="col">
              <i className="bx bx-message-detail icon" />
            </div>
            <div className="col">
              <h1>Get In Touch</h1>
              <p>Contact us for more information or support!</p>
            </div>
          </div>
          <div className="right">
            <a href="/contact" className="button">
              Contact Us
            </a>
            <ul>
              <li>
                <a
                  aria-label="Close Announcement"
                  onClick={() =>
                    setCloseAnnouncement({ version: 2, invisible: true })
                  }
                >
                  <i className="icon bx bx-x" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      ) : version === "3" ? (
        <div
          style={{ border: border ? "1px solid #000" : "none" }}
          id="wrapper3"
          className="wrapper"
        >
          <div className="left">
            <div className="col">
              <i className="bx bx-news icon" />
            </div>
            <div className="col">
              <h1>Stay Informed</h1>
              <p>
                Signup to our newsletter to get the latest news and updates.
              </p>
            </div>
          </div>
          <div className="right">
            <input type="text" placeholder="Enter your email" />
            <button>Sign Up</button>
            <ul>
              <li>
                <a
                  aria-label="Close Announcement"
                  onClick={() =>
                    setCloseAnnouncement({ version: 3, invisible: true })
                  }
                >
                  <i className="icon bx bx-x" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div
          style={{ border: border ? "1px solid #000" : "none" }}
          className="wrapper"
          id="wrapper4"
        >
          <div className="left">
            <div className="col">
              <i className="bx bx-group icon" />
            </div>
            <div className="col">
              <h1>See How You can Help</h1>
              <p>
                Learn how you can help us make a difference in the community.
              </p>
            </div>
          </div>
          <div className="right">
            <a href="" className="button">Volunteer</a>
            <a href="" className="button">Donate</a>
            <ul>
              <li>
                <a
                  aria-label="Close Announcement"
                  onClick={() =>
                    setCloseAnnouncement({ version: 4, invisible: true })
                  }
                >
                  <i className="icon bx bx-x" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Announcement;

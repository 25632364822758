import React from "react";
import "./Page.css";
import { Helmet } from "react-helmet";
import { Announcement, ContForm, ContHero, Divide, Footer, Navbar } from "../../../containers";

const Contact = () => {
  return (
    <main className="view contact">
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <section className="container">
        <Navbar transparent={true} color='#000' location="contact" />
        <div className="content">
          <ContHero />
          <ContForm />
          <Divide />
          <Announcement />
          <Footer />
        </div>
      </section>
    </main>
  );
};

export default Contact;

import React from "react";
import "./Component.css";

const Button = ({
  text,
  icon,
  color,
  background,
  hover,
  hoverColor,
  iconSide,
  fs = '1rem',
  padding = "1rem 1.5rem",
  border,
  inverse,
  onClick,
}) => {


  return (
    <button
      style={{
        background: `${background ? background : ""}`,
        color: `${color ? color : ""}`,
        padding: `${padding}`,
        border: `${border ? border : "1px solid transparent"}`,
        fontSize: `${fs}`,
      }}
      className={`component button ${hover ? 'hover' : ''} ${inverse ? 'inverse' : ''}`}
      onClick={onClick}
    >
      {icon && iconSide === "left" ? <i className={`bx ${icon}`} /> : null}
      {text ? <p>{text}</p> : null}
      {icon && iconSide === "right" ? <i className={`bx ${icon}`} /> : null}
    </button>
  );
};

export default Button;

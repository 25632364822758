import React, { useEffect } from "react";
import { useAuth } from "../hooks";
import { PageLoader } from "../containers";

const RequireAuth = ({ children }) => {
  const [loading, setLoading] = React.useState(true);
  const auth = useAuth();

  useEffect(() => {
    document.title = "Loading...";

    async function handleCheckAuth() {
      const username = await auth.checkAuth();

      if(!username) {
        auth.logout();
        return window.location.href = "/login";
      }
      setLoading(false);
    }
    handleCheckAuth();
  }, []);

  return <>{loading ? <PageLoader /> : children}</>;
};

export default RequireAuth;

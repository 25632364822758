import { CircularProgress } from "@mui/material";

const PageLoader = () => {
  return (
    <div
      className="container pageloader"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: '#77509F',
        background: '#090D11'
      }}
    >
        <CircularProgress color="inherit" />
    </div>
  );
};

export default PageLoader;

import Img1 from "./Img1.jpg";
import Img2 from "./Img2.jpg";
import Img3 from "./Img3.jpg";
import Img4 from "./Img4.jpg";
import Img5 from "./Img5.jpg";
import Img6 from "./Img6.jpg";
import Img7 from "./Img7.jpg";
import Img8 from "./Img8.jpg";
import Img9 from "./Img9.jpg";
import Img10 from "./Img10.jpg";
import Img11 from "./Img11.jpg";
import Img12 from "./Img12.jpg";
import Img13 from "./Img13.jpg";
import Img14 from "./Img14.jpg";
import Img15 from "./Img15.jpg";
import Img16 from "./Img16.jpg";
import Img17 from "./Img17.jpg";

const ImgInfo = [
  {
    id: 1,
    src: Img1,
    title: "Title1",
    descrip: "Img1",
  },
  {
    id: 2,
    src: Img2,
    title: "Title2",
    descrip: "Img2",
  },
  {
    id: 3,
    src: Img3,
    title: "Title3",
    descrip: "Img3",
  },
  {
    id: 4,
    src: Img4,
    title: "Title4",
    descrip: "Img4",
  },
  {
    id: 5,
    src: Img5,
    title: "Title5",
    descrip: "Img5",
  },
  {
    id: 6,
    src: Img6,
    title: "Title6",
    descrip: "Img6",
  },
  {
    id: 7,
    src: Img7,
    title: "Title7",
    descrip: "Img7",
  },
  {
    id: 8,
    src: Img8,
    title: "Title8",
    descrip: "Img8",
  },
  {
    id: 9,
    src: Img9,
    title: "Title9",
    descrip: "Img9",
  },
  {
    id: 10,
    src: Img10,
    title: "Title10",
    descrip: "Img10",
  },
  {
    id: 11,
    src: Img11,
    title: "Title11",
    descrip: "Img11",
  },
  {
    id: 12,
    src: Img12,
    title: "Title12",
    descrip: "Img12",
  },
  {
    id: 13,
    src: Img13,
    title: "Title13",
    descrip: "Img13",
  },
  {
    id: 14,
    src: Img14,
    title: "Title14",
    descrip: "Img14",
  },
  {
    id: 15,
    src: Img15,
    title: "Title15",
    descrip: "Img15",
  },
  {
    id: 16,
    src: Img16,
    title: "Title16",
    descrip: "Img16",
  },
  {
    id: 17,
    src: Img17,
    title: "Title17",
    descrip: "Img17",
  },
];

export { ImgInfo };

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { CookiePNG } from "../../assets";
import "./Component.css";

const Cookie = () => {
  const [accepted, setAccepted] = useState(false);
  const [cookieRemoved, setCookieRemoved] = useState(true);

  async function handleCookieAccept() {
    await Cookies.set("CookieAccepted", "true");
    setAccepted(true);
    setTimeout(() => {
      setCookieRemoved(true);
    }, 500);
  }

  useEffect(() => {
    const cookieAccepted = Cookies.get("CookieAccepted") === "true";

    if (cookieAccepted) {
      setCookieRemoved(true);
    } else {
        setCookieRemoved(false);
    }
  }, []);

  return (
    <>
      {!cookieRemoved ? (
        <div className={`component cookie ${accepted ? "accepted" : ""}`}>
          <div className="container">
            <div className="wrapper">
              <img src={CookiePNG} alt="Cookie Image" />
              <h1>Cookies</h1>
              <p>We use cookies to make your experience better.</p>
              <div className="buttons">
                <a href="/cookies">Cookie Policy</a>
                <button onClick={() => handleCookieAccept()}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        null
      )}
    </>
  );
};

export default Cookie;

import React from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { axios } from "../../../utils";
import { useAuth } from "../../../hooks";
import "./Container.css";

const LoginContainer = ({ onClickForgotPass, providedUsernameFunc }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(
    localStorage.getItem("rememberMe") === "true" ? true : false
  );
  const [credentals, setCredentials] = React.useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = React.useState({ show: false, message: "" });
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  async function handleRemember() {
    if (rememberMe) {
      setRememberMe(false);
      localStorage.setItem("rememberMe", false);
    } else {
      setRememberMe(true);
      localStorage.setItem("rememberMe", true);
    }
  }

  async function handleLogin(e) {
    e.preventDefault();
    setButtonLoading(true);

    try {
      const { location, err } = await fetchLocation();

      if (err) {
        setButtonLoading(false);
        return setError({
          show: true,
          message: "There was an error logging in 1",
        });
      }

      const response = await axios.post(
        "public/auth/login",
        {
          username: credentals.username,
          password: credentals.password,
          confirmPassword: credentals.confirmPassword,
          location,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      const { success, access, refresh } = response.data;
      setButtonLoading(false);

      if (!success) {
        return setError({ show: true, message: "Invalid credentials" });
      }

      // Set the tokens - Access 15 minutes, Refresh 7 days

      Cookies.set("CCPR_ACCESS_TOKEN", access, {
        expires: 30,
        // ENABLE IN PRODUCTION
        // secure: true,
        sameSite: "strict",
        path: "/",
      });

      Cookies.set("CCPR_REFRESH_TOKEN", refresh, {
        expires: 30,
        // ENABLE IN PRODUCTION
        // secure: true,
        sameSite: "strict",
        path: "/",
      });

      // Never expire
      Cookies.set('CCPR_HAS_LOGGED_IN', true, { expires: 365, path: '/' });

      // Redirect to dashboard
      auth.login(credentals.username);
      navigate('/dashboard');
      
    } catch (error) {
      setButtonLoading(false);
      console.log(error);
      try {
        const status = error.response.status;
        if (status === 400) {
          return setError({
            show: true,
            message: "Please fill out all fields",
          });
        } else if (status === 401) {
          setError({ show: true, message: "Invalid credentials" });
        } else if (status === 500) {
          return setError({
            show: true,
            message: "There was an error logging in 2",
          });
        }
      } catch (error) {
        console.log(error);
        return setError({ show: true, message: "No response..." });
      }
    }
  }

  async function fetchLocation() {
    try {
      const response = await axios.get("https://geolocation-db.com/json/");
      const { IPv4, city, state, country_name } = response.data;
      const location = {
        ip: IPv4,
        city,
        state,
        country: country_name,
      };

      return { location };
    } catch (error) {
      console.log(error);
      return { success: false, err: "Unable to Retrieve" };
    }
  }

  React.useEffect(() => {
    setError({ show: false, message: "" });
    providedUsernameFunc(credentals.username);
  }, [credentals]);

  return (
    <div className="container login">
      <div className="wrapper">
        <div className="top">
          <h1>{Cookies.get('CCPR_HAS_LOGGED_IN') ? 'Welcome Back' : 'Welcome'}</h1>
          <p>Login to continue</p>
        </div>
        {error.show ? (
          <div className="response">
            <div>
              {error.message
                ? error.message
                : "There was an error while logging in"}
            </div>
          </div>
        ) : null}
        <form onSubmit={(e) => handleLogin(e)}>
          <div className="input-container">
            <label htmlFor="email">Username or Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={credentals.username}
              onChange={(e) =>
                setCredentials({ ...credentals, username: e.target.value })
              }
            />
          </div>
          <div className="input-container">
            <label htmlFor="email">Password</label>
            <div className="container">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={credentals.password}
                onChange={(e) =>
                  setCredentials({ ...credentals, password: e.target.value })
                }
              />
              <i
                onClick={() => setShowPassword(!showPassword)}
                className={`icon bx bx-${showPassword ? "show" : "hide"}`}
              />
            </div>
          </div>
          <div className="input-container false">
            <label htmlFor="Confirm Password">Confirm Password</label>
            <input
              type="password"
              id="Confirm Password"
              name="Confirm Password"
              value={credentals.confirmPassword}
              onChange={(e) =>
                setCredentials({
                  ...credentals,
                  confirmPassword: e.target.value,
                })
              }
            />
          </div>
          <div className="cta">
            <div onClick={() => handleRemember()} className="left">
              <div className={`toggle ${rememberMe ? "on" : "off"}`}>
                <div className="button"></div>
              </div>
              <div className="text">Remember Me</div>
            </div>
            <div className="right">
              <a onClick={() => onClickForgotPass()}>Forgot Password?</a>
            </div>
          </div>
          <button
            disabled={
              (credentals.password ||
                credentals.username ||
                credentals.confirmPassword) &&
              buttonLoading === false
                ? false
                : true
            }
            onClick={(e) => handleLogin(e)}
            className={`${buttonLoading ? "loading" : ""}`}
          >
            <p className="text">Login</p>
          </button>
        </form>
        <a href="">
          Powered by <span>RGMProductions</span>
        </a>
      </div>
    </div>
  );
};

export default LoginContainer;

import React from "react";
import "./Container.css";
import { CCPR } from "../../../assets";

const DonoBlurb = () => {
  return (
    <div className="container donoblurb">
      <div className="top">
        <h1>Donate to Columbia County Pathways for Recovery</h1>
        <p>
          By donating to CCPR, you can help fund programs and expand existing
          resources, providing support to individuals recovering from substance
          use disorder.
        </p>
        <small>
          Columbia County Pathways to Recovery is a 501(c)(3) nonprofit
          organization qualified to accept deductible donations under the IRC.
        </small>
      </div>
      <div className="list">
        <div className="item">
          <div className="icon">
            <img src={CCPR} alt="Hello!" />
          </div>
          <h2>Help Fund Programs</h2>
          <p>
            Your donation will directly contribute to funding programs that
            support individuals in their recovery journey.
          </p>
        </div>
        <div className="item">
          <div className="icon">
            <img src={CCPR} alt="Hello!" />
          </div>
          <h2>Help Fund Programs</h2>
          <p>
            Your donation will directly contribute to funding programs that
            support individuals in their recovery journey.
          </p>
        </div>
        <div className="item">
          <div className="icon">
            <img src={CCPR} alt="Hello!" />
          </div>
          <h2>Help Fund Programs</h2>
          <p>
            Your donation will directly contribute to funding programs that
            support individuals in their recovery journey.
          </p>
        </div>
      </div>
      <div className="buttons">
        <button>Donate</button>
        <a href="">
          See our Sponsors <i className="bx bx-chevron-right" />
        </a>
      </div>
    </div>
  );
};

export default DonoBlurb;

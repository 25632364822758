import React from "react";
import { CircularProgress, Pagination } from "@mui/material";
import { HomeBG } from "../../../assets";
import "./Container.css";

const SearchResults = ({ search, resultsPerPage }) => {
  return (
    <div className="container searchresults">
      <h1>Search Results</h1>
      <Results search={search} resultsPerPage={resultsPerPage} />
    </div>
  );
};

const Results = ({ search, resultsPerPage }) => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [results, setResults] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      // Fetch data here
      setTimeout(() => {
        setLoading(false);
        setResults([
          {
            id: 1,
            thumbNail: HomeBG,
            title: "Home",
            text: "Lorem <span>ipsum</span> dolor sit amet consectetur adipisicing elit. Libero eligendi ex corrupti officia ullam exercitationem",
            link: "/",
          },
          {
            id: 2,
            thumbNail: HomeBG,
            title: "Home",
            text: "Lorem <span>ipsum</span> dolor sit amet consectetur adipisicing elit. Libero eligendi ex corrupti officia ullam exercitationem",
            link: "/",
          },
          {
            id: 3,
            thumbNail: HomeBG,
            title: "Home",
            text: "Lorem <span>ipsum</span> dolor sit amet consectetur adipisicing elit. Libero eligendi ex corrupti officia ullam exercitationem",
            link: "/",
          },
          {
            id: 4,
            thumbNail: HomeBG,
            title: "Home",
            text: "Lorem <span>ipsum</span> dolor sit amet consectetur adipisicing elit. Libero eligendi ex corrupti officia ullam exercitationem",
            link: "/",
          },
          {
            id: 5,
            thumbNail: HomeBG,
            title: "Home",
            text: "Lorem <span>ipsum</span> dolor sit amet consectetur adipisicing elit. Libero eligendi ex corrupti officia ullam exercitationem",
            link: "/",
          },
          {
            id: 6,
            thumbNail: HomeBG,
            title: "Home",
            text: "Lorem <span>ipsum</span> dolor sit amet consectetur adipisicing elit. Libero eligendi ex corrupti officia ullam exercitationem",
            link: "/",
          },
        ]);
      }, 1200);
    }

    fetchData();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedResults = results.slice(
    (page - 1) * resultsPerPage,
    page * resultsPerPage
  );

  return (
    <>
      <div className="results">
        {loading ? (
          <div className="container skel">
            {[...Array(resultsPerPage).keys()].map((index) => (
              <div key={index} className="result">
                <div className="img-skel"></div>
                <div
                  style={{
                    width: `${
                      Math.floor(Math.random() * (225 - 150 + 1)) + 150
                    }px`,
                  }}
                  className="title-skel"
                ></div>
                <div className="text-skel">
                  <span style={{ height: "22.8px", width: "100%" }}></span>
                  <span style={{ height: "22.8px", width: "100%" }}></span>
                  <span
                    style={{
                      height: "22.8px",
                      width: `${
                        Math.floor(Math.random() * (100 - 10 + 1)) + 10
                      }%`,
                    }}
                  ></span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="container">
            {paginatedResults.map((result) => (
              <div key={result.id} className="result">
                <img src={result.thumbNail} alt="" className="thumbnail" />
                <h2 className="title">{result.title}</h2>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{ __html: result.text }}
                />
                <a href={result.link} className="link">
                  {(() => {
                    const words = [
                      "Explore",
                      "Discover",
                      "Find Out",
                      "Check It Out",
                    ];
                    const randomIndex = Math.floor(
                      Math.random() * words.length
                    );
                    return words[randomIndex];
                  })()}
                  <i className="bx bx-chevron-right" />
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
      <Pagination
        sx={{ fontWeight: "600", fontFamily: "Red Hat Display, sans-serif" }}
        className="pagination"
        count={Math.ceil(results.length / resultsPerPage)}
        page={page}
        onChange={handlePageChange}
        color="secondary"
      />
    </>
  );
};

export default SearchResults;

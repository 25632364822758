import React from 'react'
import './Container.css'

const SearchCTA = () => {
  return (
    <div className='container searchcta'>
      <h2>Couldn't Find What You're Looking For?</h2>
      <p>
        Reach our to us or view a list of our resources.
      </p>
      <div className="buttons">
        <a href='/contact'>Contact Us</a>
        <a href='/resources'>Resources</a>
      </div>
    </div>
  )
}

export default SearchCTA

import React from "react";
import { AlertContext } from "../../../contexts";
import { Phone2 } from "../../../assets";
import "./Container.css";

const HLCall = () => {
  const { createAlert, copyToClipboard, handleCallClick } =
    React.useContext(AlertContext);

  const handleAlertClick = (e) => {
    e.preventDefault();
    createAlert({
      title: "Call Us",
      message:
        "Call us at <span>1-877-467-3365</span> from anywhere in the United states 9am-9pm EST. A live volunteer will answer the phone and assist you with your request. <span>For emergencies, call 911.<span>",
      buttons: {
        1: {
          text: "Copy Number",
          onClick: () => copyToClipboard("1-877-467-3365", "Phone Number"),
          close: true,
        },
        2: {
          text: "Call",
          onClick: () => handleCallClick("1-877-467-3365"),
          close: true,
        },
      },
    });
  };

  return (
    <div className="container hlcall">
      <div className="wrapper">
        <img src={Phone2} alt="Phone Icon" />
        <div className="container">
          <h2>How it Works</h2>
          <p>
            Call us at {""}
            <a href="tel:1-877-467-3365" onClick={(e) => handleAlertClick(e)}>
              1-877-467-3365
            </a>
            {""} from anywhere in the United states 9am-9pm EST. A live
            volunteer will answer the phone and assist you with your request.{" "}
            <span>
              For emergencies, call{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleCallClick("911");
                }}
                href="tel:911"
              >
                911
              </a>
              .
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HLCall;

import React from "react";
import { Button } from "../../../components";
import "./Container.css";

const DonoHero = () => {
  return (
    <div className="container donohero">
      <div className="container">
        <h1>Support Recovery</h1>
        <p>
          Welcome to the donation page of Columbia County Pathways for Recovery.
          Your contribution can help change lives and provide support to
          individuals recovering from substance use disorder.
        </p>
        <div className="buttons">
          <Button
            text="Donate Now"
            hover={true}
            background="#77509F"
            color="#fff"
          />
          <Button
            text="Learn More"
            border={"1px solid #77509F"}
            inverse={true}
            background="transparent"
            color="#fff"
          />
        </div>
      </div>
    </div>
  );
};

export default DonoHero;

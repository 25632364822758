import React from "react";
import {
  NotificationContext,
  EasyModalContext,
  AlertContext,
} from "../../../contexts";
import "./Container.css";

const HomeCTA = ({ version }) => {
  const { createModal } = React.useContext(EasyModalContext);
  const { useNotificationPreset, createNotification } =
    React.useContext(NotificationContext);
  const { createAlert, copyToClipboard, handleCallClick } =
    React.useContext(AlertContext);

  return (
    <>
      {version === "first" ? (
        <div className="container homecta first">
          <div className="left">
            <div className="col">
              <i
                onClick={() =>
                  createModal("phone", "Phone Numbers", {
                    1: {
                      title: "Emergerncy Services",
                      number: "911",
                      aNumber: "911",
                      avail: "24/7",
                    },
                    2: {
                      title: "Mobile Crisis Assessment Team (MCAT)",
                      number: "(518) 943-5555",
                      aNumber: "+15189435555",
                      avail: "8am to 10pm / 7 days",
                    },
                    3: {
                      title: "CCPR Hotline",
                      number: "877-HOPE-365 (877-467-3365)",
                      aNumber: "+18774673365",
                      avail: "9am to 9pm / 7 days",
                    },
                  })
                }
                className="bx bx-phone icon"
              />
            </div>
            <div className="col">
              <h1>Help is just a call away</h1>
              <p>
                Get the support you need to start your recovery journey.{" "}
                <span>
                  {" "}
                  For emergencies, call <a onClick={(e) => {
                      e.preventDefault();
                      createAlert({
                        title: "Emergency Services",
                        message:
                          "If you are having a crisis or an emergency, call <span>911</span>",
                        buttons: {
                          1: {
                            text: "Copy",
                            onclick: () => copyToClipboard("911", "Phone Number"),
                            close: true,
                          },
                          2: {
                            text: "Call",
                            onclick: () => handleCallClick("911"),
                            close: false,
                          }
                        }
                      });
                    }} href="">911</a> or{" "}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      createAlert({
                        title: "Hotline",
                        message:
                          "Call us at <span>1-877-467-3365</span> from anywhere in the United states 9am-9pm EST. A live volunteer will answer the phone and assist you with your request. <span>For emergencies, call 911.<span>",
                        buttons: {
                          1: {
                            text: "Copy",
                            onclick: () => copyToClipboard("877-467-3365", "Phone Number"),
                            close: true,
                          },
                          2: {
                            text: "Call",
                            onclick: () => handleCallClick("877-467-3365"),
                            close: false,
                          },
                        },
                      });
                    }}
                    href="tel"
                  >
                    877-467-3365
                  </a>
                </span>
                . Our help line is open
                <span> 9am - 9pm EST.</span>
              </p>
            </div>
          </div>
          <div className="right">
            <a href="/help-line">Help Line</a>
            <a
              onClick={(e) => {
                e.preventDefault();
                useNotificationPreset("construction");
              }}
              href="/resources"
            >
              View Resources
            </a>
          </div>
        </div>
      ) : (
        <div className="container homecta second">
          <h1>Subscribe to our Newsletter</h1>
          <p>Stay updated with our latest news and events.</p>
          <div className="input-container">
            <input type="email" placeholder="Your email address" />
            <button
              onClick={() =>
                createNotification(
                  "orange",
                  "Feature not Available",
                  "Sorry, this feature isn't currently available",
                  "bxs-traffic-barrier"
                )
              }
            >
              Subscribe
            </button>
          </div>
          <small>By joining, you agree to our Terms and Conditions</small>
        </div>
      )}
    </>
  );
};

export default HomeCTA;

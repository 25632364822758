import React from "react";
import { HomeBG2, RCBG, RCBG2 } from "../../../assets";
import "./Container.css";

const HomeFeatProg = () => {
  return (
    <div className="container homefeatprog">
      <div className="top">
        <div className="left">
          <h1>Discover Exciting Events</h1>
          <p>
            Columbia County Pathways to Recovery offers a variety of programs,
            meetings and events to help you on your journey to recovery. From
            support groups to workshops, we have something for everyone.
          </p>
        </div>
        <div className="right">
          <a href="/calendar">View Events</a>
        </div>
      </div>
      <Events />
    </div>
  );
};

const Events = () => {
  const [events, setEvents] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      const eventsArr = [
        {
          id: 5,
          title: "Event 1 Title",
          status: "completed",
          icon: "bx-check",
          date: "12/12/2021",
          time: "10:00AM - 12:00PM",
          location: {
            name: "1 Taconic Place, Chatham, NY 12037",
            link: "https://www.google.com/maps",
          },
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
          links: [
            {
              name: "Link 1",
              link: "https://www.google.com",
            },
            {
              name: "Link 2",
              link: "https://www.google.com",
            },
            {
              name: "Link 3",
              link: "https://www.google.com",
            },
          ],
          images: [
            {
              name: "Image 1",
              description: "Image 1 description",
              link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
            },
            {
              name: "Image 2",
              description: "Image 2 description",
              link: "https://www.google.com",
            },
            {
              name: "Image 3",
              description: "Image 3 description",
              link: "https://www.google.com",
            },
          ],
          thumbnail: {
            src: RCBG2,
            alt: "Event 1",
          },
          tags: ["Support", "Meeting", "Free Food"],
        },
        {
          id: 2,
          title: "Event 1 Title",
          status: "completed",
          icon: "bx-check",
          date: "12/12/2021",
          time: "10:00AM - 12:00PM",
          location: {
            name: "1 Taconic Place, Chatham, NY 12037",
            link: "https://www.google.com/maps",
          },
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
          links: [
            {
              name: "Link 1",
              link: "https://www.google.com",
            },
            {
              name: "Link 2",
              link: "https://www.google.com",
            },
            {
              name: "Link 3",
              link: "https://www.google.com",
            },
          ],
          images: [
            {
              name: "Image 1",
              description: "Image 1 description",
              link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
            },
            {
              name: "Image 2",
              description: "Image 2 description",
              link: "https://www.google.com",
            },
            {
              name: "Image 3",
              description: "Image 3 description",
              link: "https://www.google.com",
            },
          ],
          thumbnail: {
            src: RCBG,
            alt: "Event 1",
          },
          tags: ["Board Meeting", "RSVP Required"],
        },
        {
          id: 4,
          title: "Event 1 Title",
          status: "completed",
          icon: "bx-check",
          date: "12/12/2021",
          time: "10:00AM - 12:00PM",
          location: {
            name: "Recovery Center",
            link: "https://www.google.com/maps",
          },
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet sapien nec felis tincidunt auctor. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi. Nullam nec velit fermentum, fermentum arcu eget, ultricies purus. Nulla facilisi.",
          links: [
            {
              name: "Link 1",
              link: "https://www.google.com",
            },
            {
              name: "Link 2",
              link: "https://www.google.com",
            },
            {
              name: "Link 3",
              link: "https://www.google.com",
            },
          ],
          images: [
            {
              name: "Image 1",
              description: "Image 1 description",
              link: "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
            },
            {
              name: "Image 2",
              description: "Image 2 description",
              link: "https://www.google.com",
            },
            {
              name: "Image 3",
              description: "Image 3 description",
              link: "https://www.google.com",
            },
          ],
          thumbnail: {
            src: HomeBG2,
            alt: "Event 1",
          },
          tags: ["Support", "Meeting", "Free Food"],
        },
      ];

      setEvents(eventsArr);
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="events">
      {!loading && events.length > 0
        ? events.map((event, index) => (
            <div key={index} className="event">
              <img src={event.thumbnail.src} alt={event.thumbnail.alt} />
              <div className="info">
                <small>
                  {new Date(event.date).toLocaleString("en-US", {
                    weekday: "short",
                  })}
                </small>
                <h3>
                  {new Date(event.date).toLocaleString("en-US", {
                    day: "numeric",
                  })}
                </h3>
                <h5>
                  {new Date(event.date).toLocaleString("en-US", {
                    month: "short",
                  })}{" "}
                  {new Date(event.date).toLocaleString("en-US", {
                    year: "numeric",
                  })}
                </h5>
              </div>
              <ul className="tags">
                {event.tags.map((tag, index) => (
                  <li key={index}>{tag}</li>
                ))}
              </ul>
              <h2>{event.title}</h2>
              <p>{event.description}</p>
              <a href={`/calendar?event=${event.date}`}>
                {(() => {
                  const words = [
                    "Explore",
                    // "Learn More",
                    "Discover",
                    "Find Out",
                    "Check It Out",
                  ];
                  const randomIndex = Math.floor(Math.random() * words.length);
                  return words[randomIndex];
                })()}
                <i className="bx bx-chevron-right" />
              </a>
            </div>
          ))
        : loading
        ? Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="event skeleton">
              <div className="img skel" />
              <ul className="tags skel">
                {Array.from({ length: Math.floor(Math.random() * 4) + 1 }).map(
                  (_, index) => (
                    <li
                      key={index}
                      style={{
                        height: "1.48625rem",
                        width: `${(() =>
                          Math.floor(Math.random() * (100 - 60 + 1)) +
                          60)()}px`,
                      }}
                    ></li>
                  )
                )}
              </ul>
              <h2
                style={{
                  width: `${(() =>
                    Math.floor(Math.random() * (225 - 150 + 1)) + 150)()}px`,
                }}
                className="skel"
              ></h2>
              <div className="skel text">
                <span style={{ height: "22.8px", width: "100%" }}></span>
                <span style={{ height: "22.8px", width: "100%" }}></span>
                <span
                  style={{
                    height: "22.8px",
                    width: `${(() =>
                      Math.floor(Math.random() * (100 - 10 + 1)) + 10)()}%`,
                  }}
                ></span>
              </div>
              <a
                style={{
                  height: "21px",
                  width: `${(() =>
                    Math.floor(Math.random() * (105 - 75 + 1)) + 75)()}px`,
                }}
                className="skel link"
              ></a>
            </div>
          ))
        : null}
    </div>
  );
};

export default HomeFeatProg;

import React from "react";
import { ImgInfo } from "../../../assets/ImgGal";
import { LightboxContext } from "../../../contexts";
import "./Container.css";

const HomeGal = () => {
  const [loading, setLoading] = React.useState(
    ImgInfo.reduce((acc, img) => {
      acc[img.id] = true;
      return acc;
    }, {})
  );
  const { createLightboxImage } = React.useContext(LightboxContext);

  const handleImageLoad = (id) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [id]: false,
    }));
  };

  return (
    <div className="container homegal">
      <h2>
        Photo <span>Gallery</span>
      </h2>
      <div className="gal">
        {ImgInfo.map((img) => (
          <div className="skel" key={img.id}>
            <img
              onLoad={() => handleImageLoad(img.id)}
              src={img.src}
              onClick={() => createLightboxImage(img.src, img.title, img.descrip)}
              alt={img.descrip}
              style={{ display: loading[img.id] ? "none" : "block" }}
            />
            {loading[img.id] && (
              <div
                style={{
                  height: `${
                    Math.floor(Math.random() * (500 - 150 + 1)) + 150
                  }px`,
                }}
                className="skel"
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeGal;

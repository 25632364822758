import React from "react";
import { NotificationContext } from "../../../contexts";
import { CCPR, RGMSVG } from "../../../assets";
import "./Container.css";

const Footer = () => {
  const { createNotification, useNotificationPreset } =
    React.useContext(NotificationContext);

  return (
    <footer className="container footer">
      <div className="top">
        <div className="col two">
          <img
            onClick={() => {
              const path = window.location.pathname;
              if (path === "/" || path === "/home")
                return window.scrollTo({ top: 0, behavior: "smooth" });
              window.location.href = "/";
            }}
            src={CCPR}
            alt="Logo"
          />
          <p>
            Join our newsletter to stay updated on our latest features and
            releases
          </p>
          <div className="input-container">
            <input type="text" placeholder="Enter your email" />
            <button
              onClick={(e) => {
                e.preventDefault();
                createNotification(
                  "orange",
                  "Feature Unavailable",
                  "Please try again later!",
                  "bxs-traffic-barrier",
                  3000
                );
              }}
            >
              Subscribe
            </button>
          </div>
          <small>
            By subscribing, you agree to our Privacy Policy and consent to
            receive updates from us
          </small>
        </div>
        <div className="col one">
          <header>About Us</header>
          <ul>
            <li>
              <a href="/about">About CCPR</a>
            </li>

            <li>
              <a href="/meetings">Meetings</a>
            </li>
            <li>
              <a href="/calendar">Calendar</a>
            </li>
            <li>
              <a href="/recovery-center">Recovery Center</a>
            </li>
            <li>
              <a href="/donate">Donate</a>
            </li>
          </ul>
        </div>
        <div className="col one">
          <header>Our Team</header>
          <ul>
            <li>
              <a href="">Lorem Ipsum</a>
            </li>
            <li>
              <a href="">Lorem Ipsum</a>
            </li>
            <li>
              <a href="">Lorem Ipsum</a>
            </li>
            <li>
              <a href="">Lorem Ipsum</a>
            </li>
            <li>
              <a href="">Lorem Ipsum</a>
            </li>
          </ul>
        </div>
        <div className="col one">
          <header>Follow Us</header>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/columbiapathwaystorecovery"
              >
                <i className="bx bxl-facebook" />
                Facebook
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/columbia-pathways-to-recovery-inc/"
              >
                <i className="bx bxl-linkedin" />
                LinkedIn
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/columbia_pathways_to_recovery/"
              >
                <i className="bx bxl-instagram" />
                Instagram
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCCFTslw0Hrx2XoznB6hlKIQ"
              >
                <i className="bx bxl-youtube" />
                Youtube
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="line" />
      <div className="copyright">
        <div className="left">
          <p>
            &copy; 2024 Columbia County Pathways to Recovery. All rights
            reserved.
          </p>
        </div>
        <div className="right">
          <ul>
            <li>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  useNotificationPreset("construction");
                }}
                href=""
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  useNotificationPreset("construction");
                }}
                href=""
              >Terms of Service</a>
            </li>
            <li>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  useNotificationPreset("construction");
                }}
                href=""
              >Cookie Policy</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="line" />
      <div className="credits">
        <p>
          Columbia County Pathways to Recovery is a 501(c)(3) nonprofit
          organization qualified to accept deductible donations under the IRC.
        </p>
        <a
          target="_blank"
          aria-label="Link to creator website"
          href="https://rgmproductions.com"
        >
          <img src={RGMSVG} alt="Created and Maintained by RGM Productions" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;

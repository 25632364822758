import React from "react";
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { AlertContext } from "../../../contexts";
import "./Container.css";

const ContForm = () => {
  const [contactInfo, setContactInfo] = React.useState({
    firstName: "",
    lastName: "",
    preferredContact: "",
    contactMethod: "",
    message: "",
    error: {
      firstName: false,
      lastName: false,
      message: false,
      preferredContact: false,
      contactMethod: false,
    },
  });
  const [loading, setLoading] = React.useState(false);
  const { createAlert, copyToClipboard, handleCallClick } =
    React.useContext(AlertContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const { firstName, lastName, preferredContact, contactMethod, message } =
      contactInfo;
    if (
      !firstName ||
      !lastName ||
      !preferredContact ||
      !contactMethod ||
      !message
    ) {
      setContactInfo({
        ...contactInfo,
        error: {
          firstName: !firstName,
          lastName: !lastName,
          message: !message,
          preferredContact: !preferredContact,
          contactMethod: !contactMethod,
        },
      });
      return;
    }
  };

  return (
    <div className="container contform">
      <ContactInfo
        createAlert={createAlert}
        copyToClipboard={copyToClipboard}
        handleCallClick={handleCallClick}
      />
      <ContactForm
        handleSubmit={handleSubmit}
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
        loading={loading}
      />
    </div>
  );
};

const ContactInfo = ({ createAlert, copyToClipboard, handleCallClick }) => {
  const handleClick = (e, version) => {
    e.preventDefault();
    if (version === 1) {
      createAlert({
        title: "Call Us",
        message:
          "Call us at <span>+0 000 000 0000</span> or click the buttons below to copy the phone number or call us directly.",
        buttons: {
          1: {
            text: "Copy",
            onClick: () => copyToClipboard("+0 000 000 0000", "Phone Number"),
            close: true,
          },
          2: {
            text: "Call",
            onClick: () => handleCallClick("+0 000 000 0000"),
            close: true,
          },
        },
      });
    }
  };

  return (
    <div className="info">
      <h2>Contact Info</h2>
      <div className="lists">
        <ul className="contact">
          <li>
            <i className="bx bx-map icon" />
            <a onClick={(e) => handleClick(e, 1)} href="#">
              1 Infinite Loop Cupertino, CA 95014
            </a>
          </li>
          <li>
            <i className="bx bx-phone icon" />
            <a href="#">1-800-MY-APPLE</a>
          </li>
          <li>
            <i className="bx bx-envelope icon" />
            <a href="#">P.O. Box 0915 Mountain View, CA 94043</a>
          </li>
        </ul>
        <ul className="socials">
          <li>
            <a href="#">
              <i className="bx bxl-facebook" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bx bxl-linkedin-square" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bx bxl-instagram" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bx bxl-youtube" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const ContactForm = ({
  handleSubmit,
  contactInfo,
  setContactInfo,
  loading,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactInfo({
      ...contactInfo,
      [name]: value,
      error: { ...contactInfo.error, [name]: false },
    });
  };

  return (
    <div className="form-container">
      <h2>Send A Message</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-container half">
          <TextField
            error={contactInfo.error.firstName}
            style={{ width: "100%" }}
            id="firstName"
            name="firstName"
            label="First Name"
            helperText={contactInfo.error.firstName ? "Required" : null}
            variant="outlined"
            value={contactInfo.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="input-container half">
          <TextField
            error={contactInfo.error.lastName}
            style={{ width: "100%" }}
            id="lastName"
            name="lastName"
            label="Last Name"
            helperText={contactInfo.error.lastName ? "Required" : null}
            variant="outlined"
            value={contactInfo.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="input-container half">
          <FormControl error={contactInfo.error.preferredContact} fullWidth>
            <InputLabel id="preferredContact-label">
              Preferred Contact Method
            </InputLabel>
            <Select
              labelId="preferredContact-label"
              id="preferredContact"
              name="preferredContact"
              value={contactInfo.preferredContact}
              label="Preferred Contact Method"
              onChange={handleChange}
            >
              <MenuItem
                style={{ fontFamily: "Red Hat Display, sans-serif" }}
                value="email"
              >
                Email
              </MenuItem>
              <MenuItem
                style={{ fontFamily: "Red Hat Display, sans-serif" }}
                value="mobile"
              >
                Mobile Phone
              </MenuItem>
              <MenuItem
                style={{ fontFamily: "Red Hat Display, sans-serif" }}
                value="phone"
              >
                Home Phone
              </MenuItem>
              <MenuItem
                style={{ fontFamily: "Red Hat Display, sans-serif" }}
                value="mail"
              >
                Mail
              </MenuItem>
            </Select>
            {contactInfo.error.preferredContact && (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="input-container half">
          <TextField
            error={contactInfo.error.contactMethod}
            style={{ width: "100%", textTransform: "capitalize" }}
            id="contactMethod"
            name="contactMethod"
            label={
              contactInfo.preferredContact
                ? contactInfo.preferredContact === "mail" ? "Address" : contactInfo.preferredContact
                :  "Contact Info"
            }
            helperText={contactInfo.error.contactMethod ? "Required" : null}
            variant="outlined"
            value={contactInfo.contactMethod}
            onChange={handleChange}
          />
        </div>
        <div className="input-container full">
          <TextField
            error={contactInfo.error.message}
            style={{ width: "100%" }}
            id="message"
            name="message"
            label="Message"
            multiline
            rows={8}
            helperText={contactInfo.error.message ? "Required" : null}
            variant="outlined"
            value={contactInfo.message}
            onChange={handleChange}
          />
        </div>
        <div className="input-container full">
          <button disabled={loading} style={{ color: "#fff" }} type="submit">
            {loading ? (
              <CircularProgress color="inherit" size={26} />
            ) : (
              <p>Submit</p>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContForm;

import React, { useRef, useState, useContext, useEffect } from "react";
import { AlertContext } from "../contexts";
import { NotificationContext } from "../contexts";
import "./css/alert.css";

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    shown: false,
    title: null,
    message: null,
    buttons: {
      1: {
        text: null,
        onClick: null,
        close: null,
      },
      2: {
        text: null,
        onClick: null,
        close: null,
      },
    },
  });

  const { createNotification } = useContext(NotificationContext);

  const createAlert = (data) => {
    console.log(data);
    setAlert({
      shown: true,
      title: data.title,
      message: data.message,
      buttons: {
        1: {
          text: data.buttons[1].text,
          onClick: data.buttons[1].onClick,
          close: data.buttons[1].close,
        },
        2: {
          text: data.buttons[2]?.text,
          onClick: data.buttons[2]?.onClick,
          close: data.buttons[2]?.close,
        },
      },
    });
  };

  const closeAlert = () => {
    setAlert({
      shown: false,
      title: null,
      message: null,
      buttons: {
        1: {
          text: null,
          onClick: null,
          close: null,
        },
        2: {
          text: null,
          onClick: null,
          close: null,
        },
      },
    });
  };

  const copyToClipboard = (text, textType) => {
    navigator.clipboard.writeText(text);
    createNotification(
      "green",
      "Copied",
      `${textType ? textType : ""} copied to clipboard`,
      "bx bx-copy"
    );
  };

  const handleCallClick = (phoneNum) => {
    const anchor = document.createElement("a");
    anchor.href = `tel:${phoneNum}`;
    createNotification(
      "green",
      "Calling",
      `Calling ${phoneNum}...`,
      "bx-phone"
    );
    anchor.click();
    anchor.remove();
  };

  useEffect(() => {
    const main = document.querySelector(".App > main");
    if (main) {
      if (alert.shown) {
        document.body.style.overflow = "hidden";
        main.style.filter =
          "blur(2px) brightness(0.5)";
      } else {
        main.style.transition =
          "filter 0.3s ease-in-out";
        document.body.style.overflow = "auto";
        main.style.filter = "none";
        setTimeout(() => {
          main.style.transition = "none";
        }, 300);
      }
    }
  }, [alert.shown]);

  return (
    <AlertContext.Provider
      value={{ createAlert, closeAlert, copyToClipboard, handleCallClick }}
    >
      {children}
      {alert.shown ? (
        <div className="alert-container">
          <div className="wrapper">
            <i className="bx bx-x util" onClick={closeAlert} />
            <h2>{alert.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: alert.message }}></p>
            <div className="buttons">
              <button
                onClick={async () => {
                  await alert.buttons[1].onClick();
                  if (alert.buttons[1].close) {
                    closeAlert();
                  }
                }}
              >
                {alert.buttons[1].text}
              </button>
              {alert.buttons[2].text ? (
                <button
                  onClick={async () => {
                    await alert.buttons[2].onClick();
                    if (alert.buttons[2].close) {
                      closeAlert();
                    }
                  }}
                >
                  {alert.buttons[2].text}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </AlertContext.Provider>
  );
};

import React from 'react'
import './Container.css'

const DonoCTA = () => {
  return (
    <div className='container donocta'>
      <h1>Discover Events and Opportunities</h1>
      <p>
        Join us to learn more about the latest events and meetings.
      </p>
      <div className="buttons">
        <a href={`/calendar?event=${new Date()}`}>View Events</a>
        <a href='/contact'>Contact Us</a>
      </div>
    </div>
  )
}

export default DonoCTA

import React, { useEffect, useState } from "react";
import "./Component.css";

const NavUp = () => {
  const [hidden, setHidden] = useState(true);
  const [direction, setDirection] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.6) {
        setHidden(false);
      } else {
        setHidden(true);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  async function handleClick() {
    if (direction === 1) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }
  }

  async function handleChangeDirection(e) {
    e.preventDefault();
    setDirection(direction * -1)
  }

  return (
    <div
      onClick={handleClick}
      className={`component navup ${hidden ? "hidden" : ""} ${direction === 1 ? '' : 'flip'}`}
      onContextMenu={handleChangeDirection}
    >
      <i className="bx bx-chevron-up" />
    </div>
  );
};

export default NavUp;

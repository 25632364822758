import React from "react";
import { CircularProgress } from "@mui/material";
import "./Container.css";
import { Calendar } from "../../../assets";

const CalHero = () => {
  return (
    <div className="container calhero">
      <div className="wrapper">
        <div className="left">
          <h1>Calendar</h1>
          <p>
            Stay up to date with all of our events and activities. From support
            meetings to fundraisers, we have it all. Check out our calendar to
            see what's happening.
          </p>
        </div>
        <div className="right">
          <img src={Calendar} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CalHero;

import Image1 from "./Image1.jpg";
import Image2 from "./Image2.jpg";
import Image3 from "./Image3.jpg";
import Image4 from "./Image4.jpg";

const galleryData = [
  {
    id: 1,
    image: Image1,
    title: "Title 1",
    description:
      "Lorem Description 1 lorem ipsum dolor sit ament, consectetur adipiscing elit.",
    color: "#fff",
  },
  {
    id: 2,
    image: Image2,
    title: "Title 2",
    description:
      "Lorem Description 2 lorem ipsum dolor sit ament, consectetur adipiscing elit.",
    color: "#fff",
  },
  {
    id: 3,
    image: Image3,
    title: "Title 3",
    description:
      "Lorem Description 3 lorem ipsum dolor sit ament, consectetur adipiscing elit.",
    color: "#fff",
  },
  {
    id: 4,
    image: Image4,
    title: "Title 4",
    description:
      "Lorem Description 4 lorem ipsum dolor sit ament, consectetur adipiscing elit.",
    color: "#fff",
  },
  {
    id: 5,
    image: 'https://t4.ftcdn.net/jpg/05/71/83/47/360_F_571834789_ujYbUnH190iUokdDhZq7GXeTBRgqYVwa.jpg',
    title: "Title 5",
    description:
      "Lorem Description 5 lorem ipsum dolor sit ament, consectetur adipiscing elit.",
    color: "#fff",
  },
  {
    id: 6,
    image: 'https://www.creativefabrica.com/wp-content/uploads/2023/04/02/Nature-Background-Graphics-66003049-1.jpg',
    title: "Title 6",
    description:
      "Lorem Description 6 lorem ipsum dolor sit ament, consectetur adipiscing elit.",
    color: "#fff",
  },
];

export { galleryData };
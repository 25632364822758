import React, { useState } from "react";
import { LightboxContext } from "../contexts/lightboxContext";
import './css/lightbox.css'

export const LightboxProvider = ({ children }) => {
  const [modal, setModal] = useState({
    shown: false,
    image: null,
    title: null,
    description: null,
  });

  const createLightboxImage = (image, title, description) => {
    setModal({ shown: true, image, title, description });
  };

  const closeLightboxImage = () => {
    setModal({ shown: false, image: null, title: null, description: null });
  };

  return (
    <LightboxContext.Provider
      value={{ createLightboxImage, closeLightboxImage }}
    >
      {children}
      {modal.shown ? (
        <div className="container lightbox">
          <div className="wrapper">
            <div onClick={closeLightboxImage} className="close">
              <i className="bx bx-x"></i>
            </div>
            <div className="content">
              <div className="image-wrapper">
                <img
                  src={modal.image}
                  alt={modal.description}
                  className="loading"
                  onLoad={(e) => {
                    e.target.classList.remove("loading");
                  }}
                />
              </div>
              <div className="info">
                <h2>{modal.title ? modal.title : null}</h2>
                <p>{modal.description ? modal.description : null}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </LightboxContext.Provider>
  );
};

import React from "react";
import Cookies from "js-cookie";
import { AuthContext } from "../contexts";
import { axios } from "../utils";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);

  const login = (user) => {
    setUser(user);
  };

  const logout = () => {
    setUser(null);
    Cookies.remove("CCPR_ACCESS_TOKEN");
    Cookies.remove("CCPR_REFRESH_TOKEN");
  };

  const checkAuth = async () => {
    const accessToken = Cookies.get("CCPR_ACCESS_TOKEN");
    const refresh = Cookies.get("CCPR_REFRESH_TOKEN");
    if (!accessToken) return logout();
    if (!refresh) return logout();

    try {
      const response = await axios.post(
        "/public/auth/verify",
        {},
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
            refresh: refresh,
          },
        }
      );

      const { success, username, newToken } = response.data;

      if (newToken) {
        Cookies.set("CCPR_ACCESS_TOKEN", newToken, { expires: 1 / 96 });
      }

      if (!success) return logout();

      await login(username);

      return username;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

import React from 'react'
import './Page.css'
import { Helmet } from 'react-helmet'

const Dashboard = () => {
  return (
    <main>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <section className="container">
        Dashboard!
      </section>
    </main>
  )
}

export default Dashboard

import React from "react";
import "./Container.css";
import { NotificationContext } from "../../../contexts";

const HomeHeroHeader = () => {
  const { useNotificationPreset } = React.useContext(NotificationContext);

  return (
    <div className="container home_hero">
      <div className="container">
        <h1>Lorem Ipsum Title</h1>
        <p>
          We are a grassroots Recovery Community Organization, developed through
          the need for voices to be heard; for education, awareness,
          de-stigmatization, and advocacy for change in regard to substance use
          disorder in our county.
        </p>
        <div className="buttons">
          <button role="link" onClick={() => (window.location.href = "/about")}>
            Learn More
          </button>
          <button
            role="link"
            onClick={() => (window.location.href = "/recovery-center")}
          >
            Recovery Center
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeHeroHeader;

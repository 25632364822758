import React, { useState, useEffect } from "react";
import { NotificationContext } from "../contexts/notificationContext";
import './css/notificationProvider.css';

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const createNotification = (
    color,
    title,
    message,
    icon,
    defaultExpiration = 5000
  ) => {
    const activeNotifications = notifications.filter(
      (notif) => !notif.hidden
    ).length;

    if (activeNotifications >= 4) {
      const oldestNotification = notifications.find((notif) => !notif.hidden);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notif) =>
          notif.id === oldestNotification.id
            ? { ...notif, hidden: true }
            : notif
        )
      );
      setTimeout(() => {
        removeNotification(oldestNotification.id);
      }, 500);
    }

    const id = Math.random().toString(36).substr(2, 9);
    const newNotification = {
      id,
      color,
      title,
      message,
      icon,
      defaultExpiration,
      hidden: false,
    };
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newNotification,
    ]);
    
    setTimeout(() => {
      setNotifications((prevNotifications) =>
        prevNotifications.map((notif) =>
          notif.id === id ? { ...notif, hidden: true } : notif
        )
      );
    }, defaultExpiration + 1000);

    setTimeout(() => {
      removeNotification(id);
    }, defaultExpiration + 1500);
  };

  const removeNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notif) => notif.id !== id)
    );
  };

  const handleManualRemove = (e) => {
    const id = e.target.parentElement.id;

    setNotifications((prevNotifications) =>
      prevNotifications.map((notif) =>
        notif.id === id ? { ...notif, hidden: true } : notif
      )
    );

    setTimeout(() => {
      removeNotification(id);
    }, 500);
  };

  const useNotificationPreset = (preset) => {
    if(preset === "construction") {
      createNotification('orange', 'Try Again Later...', 'This page is currently under construction!', 'bxs-traffic-barrier')
    } else if(preset === "error") {

    } else if(preset === "success") {

    } else {
      return;
    }
  }

  return (
    <NotificationContext.Provider value={{ createNotification, useNotificationPreset }}>
      {children}
      <div className="notification-container">
        <div className="wrapper">
          {notifications.map(
            ({
              id,
              color,
              title,
              message,
              icon,
              defaultExpiration,
              hidden,
            }) => (
              <div
                key={id}
                id={id}
                className={`toast ${hidden ? "hidden" : ""}`}
                style={{ color, borderLeft: `6px solid ${color}` }}
              >
                <div className="left">
                  <i className={`bx ${icon}`}></i>
                </div>
                <div className="right">
                  <p className="title">{title}</p>
                  <p className="message">{message}</p>
                </div>
                <i className="bx bx-x button" onClick={handleManualRemove}></i>
                <div
                  className="progress"
                  style={{
                    backgroundColor: color,
                    animationDuration: `${defaultExpiration}ms`,
                  }}
                ></div>
              </div>
            )
          )}
        </div>
      </div>
    </NotificationContext.Provider>
  );
};

import React from "react";
import { Helmet } from "react-helmet";
import {
  Announcement,
  DonoCTA,
  Footer,
  HLCall,
  HLHero,
  Navbar,
} from "../../../containers";
import "./Page.css";
import { NavUp } from "../../../components";

const HelpLine = () => {
  return (
    <main className="view helpline">
      <Helmet>
        <title>Help Line</title>
      </Helmet>
      <section className="container">
        <Navbar transparent={true} color="" location="helpline" />
        <NavUp />
        <div className="content">
          <HLHero />
          <HLCall />
          <Announcement version="1" />
          <DonoCTA />
          <Footer />
        </div>
      </section>
    </main>
  );
};

export default HelpLine;
